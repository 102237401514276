import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useRef, useState } from "react";
import {
  UploadDocsFunctionParams,
  UploadPhotosFunctionParams,
} from "types/functions";
import {
  useUploadFiles,
  useGetListings,
  useGetPhotos,
  useDownloadDocuments,
} from "hooks";
import { useDocumentStore, useListingsStore, usePhotoStore } from "store";
import {
  LoadingIndicator,
  ErrorMessage,
  PaperSurface,
  HorizontalPhotoList,
  DocumentPreview,
} from "components/common";
import { ListingDocumentType } from "types/entities";
import { ViewWrapper } from "components/wrappers";
import { notification } from "antd";

type MappedListingOption = {
  label: string;
  value: string;
};

const INITIAL_DOCUMENTS_FORM: UploadDocsFunctionParams = {
  listingId: "",
  isTitle: false,
  files: null,
};

const INITIAL_PHOTOS_FORM: UploadPhotosFunctionParams = {
  listingId: "",
  files: null,
};

export const ManageFiles: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  // Axios source used for cancelling photo downloads
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const {
    errorDocs,
    errorPhotos,
    loadingDocs,
    loadingPhotos,
    uploadDocs,
    uploadPhotos,
  } = useUploadFiles();
  const {
    loading: loadingListings,
    error: errorListings,
    getListings,
  } = useGetListings();
  const {
    getPhotos,
    loading: loadingGetPhotos,
    error: errorGetPhotos,
  } = useGetPhotos();
  const {
    downloadDocuments: getDocuments,
    loading: loadingGetDocuments,
    error: errorGetDocuments,
  } = useDownloadDocuments();
  const { listings } = useListingsStore();
  const { photos } = usePhotoStore();
  const { documents } = useDocumentStore();
  const [mappedListingOptions, setMappedListingOptions] = useState<
    MappedListingOption[]
  >([]);
  const [docsForm, setDocsForm] = useState({ ...INITIAL_DOCUMENTS_FORM });
  const [photosForm, setPhotosForm] = useState({ ...INITIAL_PHOTOS_FORM });
  const [awaitingApproval, setAwaitingApproval] = useState<boolean>(true);
  const handleUploadDocs = (newDocsForm: UploadDocsFunctionParams) => {
    setDocsForm({ ...newDocsForm });
    uploadDocs(newDocsForm).then((success: boolean) => {
      if (success && docsForm.listingId) {
        api.success({
          message: `Successfully uploaded documents`,
          placement: "bottomRight",
        });
        fetchDocuments(docsForm.listingId);
      }
    });
  };

  const handleUploadPhotos = (newPhotosForm: UploadPhotosFunctionParams) => {
    setPhotosForm({ ...newPhotosForm });
    uploadPhotos(newPhotosForm).then((success: boolean) => {
      if (success && photosForm.listingId) {
        api.success({
          message: `Successfully uploaded photos`,
          placement: "bottomRight",
        });
        fetchPhotos(photosForm.listingId);
      }
    });
  };

  const mapListingsToOptions = () => {
    const newMappedListingOptions: MappedListingOption[] = [];
    listings.forEach((listing) => {
      let address = "";
      address += listing.address_line_1 + ", ";
      if (listing.address_line_2) address += listing.address_line_2 + ", ";
      address += listing.city;

      newMappedListingOptions.push({
        label: address,
        value: listing.id,
      });
    });
    setMappedListingOptions([...newMappedListingOptions]);
  };

  const fetchListings = () => {
    getListings({ awaitingApproval });
  };

  const fetchPhotos = (listingId: string) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    getPhotos({ listingId, signal: abortControllerRef.current.signal });
  };

  const fetchDocuments = (listingId: string) => {
    const documentType = docsForm.isTitle
      ? ListingDocumentType.TITLE
      : ListingDocumentType.INSPECTION;
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    getDocuments({
      listingId,
      signal: abortControllerRef.current.signal,
      type: documentType,
    });
  };

  useEffect(() => {
    fetchListings();
  }, [awaitingApproval]);

  useEffect(() => {
    mapListingsToOptions();
  }, [listings]);

  useEffect(() => {
    if (photosForm.listingId) fetchPhotos(photosForm.listingId);
  }, [photosForm.listingId]);

  useEffect(() => {
    if (docsForm.listingId) fetchDocuments(docsForm.listingId);
  }, [docsForm.listingId, docsForm.isTitle]);

  return (
    <ViewWrapper title="Manage files">
      {contextHolder}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Documents</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={awaitingApproval}
                  onChange={(e) => setAwaitingApproval(e.target.checked)}
                />
              }
              label="Listings awaiting approval"
            />
            {loadingListings ? (
              <LoadingIndicator indicatorColor="white" size={24} />
            ) : (
              <TextField
                select
                label="Select listing"
                value={docsForm.listingId}
                onChange={(e) =>
                  setDocsForm({ ...docsForm, listingId: e.target.value })
                }
              >
                {mappedListingOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {errorListings && <ErrorMessage />}

            <Button
              variant="contained"
              component="label"
              disabled={!docsForm.listingId}
              style={{ marginTop: 10 }}
            >
              {loadingDocs ? (
                <LoadingIndicator indicatorColor="white" size={24} />
              ) : (
                "Upload Documents"
              )}

              <input
                type="file"
                hidden
                multiple
                onChange={(e) =>
                  handleUploadDocs({ ...docsForm, files: e.target.files })
                }
              />
            </Button>
            <FormControlLabel
              label="Title document"
              control={
                <Checkbox
                  checked={docsForm.isTitle}
                  onChange={(e) =>
                    setDocsForm({ ...docsForm, isTitle: e.target.checked })
                  }
                />
              }
            />
            {errorDocs && <ErrorMessage />}
            <div style={{ marginTop: 10 }}>
              <DocumentPreview
                documents={documents}
                error={!!errorGetDocuments}
                loading={loadingGetDocuments}
              />
            </div>
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Photos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={awaitingApproval}
                  onChange={(e) => setAwaitingApproval(e.target.checked)}
                />
              }
              label="Listings awaiting approval"
            />
            {loadingListings ? (
              <LoadingIndicator size={24} />
            ) : (
              <TextField
                select
                label="Select listing"
                value={photosForm.listingId}
                onChange={(e) =>
                  setPhotosForm({ ...photosForm, listingId: e.target.value })
                }
              >
                {mappedListingOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {errorListings && <ErrorMessage />}
            <Button
              variant="contained"
              component="label"
              disabled={!photosForm.listingId}
              style={{ marginTop: 10 }}
            >
              {loadingPhotos ? (
                <LoadingIndicator indicatorColor="white" size={24} />
              ) : (
                "Upload Photos"
              )}
              <input
                type="file"
                hidden
                multiple
                onChange={(e) =>
                  handleUploadPhotos({ ...photosForm, files: e.target.files })
                }
              />
            </Button>
            {errorPhotos && <ErrorMessage />}
          </FormGroup>
          {photosForm.listingId && (
            <PaperSurface style={{ padding: 10 }}>
              {errorGetPhotos && <ErrorMessage />}
              {loadingGetPhotos ? (
                <LoadingIndicator />
              ) : (
                <HorizontalPhotoList photos={photos} />
              )}
            </PaperSurface>
          )}
        </AccordionDetails>
      </Accordion>
    </ViewWrapper>
  );
};
