import React, { useEffect } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import "../../assets/styles/components/messages/styles.css";

import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import "@sendbird/uikit-react/dist/index.css";
import { useChatStore, useUserStore } from "store";
import ChannelList from "@sendbird/uikit-react/ChannelList";
import Channel from "@sendbird/uikit-react/Channel";
import { GroupChannel } from "@sendbird/chat/groupChannel";
import { ErrorMessage, InputField, LoadingIndicator } from "components/common";
import { UserInStore } from "types/store";
import { useSendbirdSupportToken } from "hooks/useSendbirdSupportToken";
import { uploadMessageFile } from "services/ChatService";

type OwnProps = {};

export const SendbirdChat: React.FC<OwnProps> = () => {
  const { loading, error, getToken } = useSendbirdSupportToken();
  const { authorizedUser } = useUserStore();
  const { supportToken } = useChatStore();
  const [isMounted, setIsMounted] = useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState("");
  const [searchStringUserName, setSearchStringUserName] = useState("");
  const [searchStringChannelName, setSearchStringChannelname] = useState("");
  const [supportMode, setSupportMode] = useState(false);

  const checkSupportEligibility = (user: UserInStore) => {
    return user?.isAdmin || user?.isListingSpecialist;
  };

  const getSendbirdUserId = (user: UserInStore) => {
    if (supportMode) return supportToken?.user_id || "";
    return user?.id || "";
  };
  const getSendbirdAccessToken = (user: UserInStore) => {
    if (supportMode) return supportToken?.token || undefined;
    return user?.sendbirdAccessToken || undefined;
  };

  const onChannelSelect = (channel: GroupChannel) => {
    if (channel && channel.url) {
      setCurrentChannelUrl(channel.url);
    }
  };

  const handleUploadFileMessage = async (
    file: File,
    quotedMessage: undefined
  ) => {
    await uploadMessageFile({
      file,
      channelUrl: currentChannelUrl,
      isSupport: supportMode,
    });
  };

  useEffect(() => {
    if (checkSupportEligibility(authorizedUser)) {
      setSupportMode(true);
    }
    setIsMounted(true);
  }, [authorizedUser]);

  useEffect(() => {
    if (supportMode) {
      getToken();
    }
  }, [supportMode]);

  return (
    <>
      {/* {checkSupportEligibility(authorizedUser) && (
        <FormControlLabel
          control={
            <Switch
              checked={supportMode}
              onChange={(e) => setSupportMode(e.target.checked)}
            />
          }
          label="Support mode"
        />
      )} */}
      <div className="messages-search-field">
        <InputField
          label="Filter by address"
          value={searchStringChannelName}
          fullWidth
          onChange={(val) => setSearchStringChannelname(val)}
        />
      </div>
      <div className="messages-search-field">
        <InputField
          label="Filter by user name"
          value={searchStringUserName}
          fullWidth
          onChange={(val) => setSearchStringUserName(val)}
        />
      </div>

      {loading && <LoadingIndicator />}
      {!loading && error && isMounted && <ErrorMessage />}
      {!loading && !error && isMounted && (
        <Box className="messages-view-container">
          <SendbirdProvider
            appId={process.env.REACT_APP_SENDBIRD_APP_ID}
            userId={getSendbirdUserId(authorizedUser)}
            accessToken={getSendbirdAccessToken(authorizedUser)}
          >
            <>
              <ChannelList
                onChannelSelect={onChannelSelect}
                queries={{
                  channelListQuery: {
                    includeEmpty: true,
                    channelNameContainsFilter: searchStringChannelName,
                    nicknameContainsFilter: searchStringUserName,
                  },
                }}
                disableAutoSelect
              />
              <div className="divider"></div>
              <Channel
                channelUrl={currentChannelUrl}
                isReactionEnabled={false}
                // @ts-ignore
                onBeforeSendFileMessage={handleUploadFileMessage}
              />
            </>
          </SendbirdProvider>
        </Box>
      )}
    </>
  );
};
