import { useState } from "react";

import { usePhotoStore } from "store";
import { GetPhotosFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetPhotos extends BaseAsyncHook {
  getPhotos: GetPhotosFunction;
}

export const useGetPhotos = (): UseGetPhotos => {
  const _storeFunc = usePhotoStore((state) => state.getPhotos);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getPhotos = async (...args: Parameters<GetPhotosFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const photos = await _storeFunc(args[0]);
      return photos;
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getPhotos,
  };
};
