import { useState } from "react";

import { useChatStore } from "store";
import { GetSendbirdSupportTokenFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseSendbirdSupportToken extends BaseAsyncHook {
  getToken: GetSendbirdSupportTokenFunction;
}

export const useSendbirdSupportToken = (): UseSendbirdSupportToken => {
  const _storeFunc = useChatStore((state) => state.getSendbirdSupportToken);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getToken = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await _storeFunc();
      return response;
    } catch (error) {
      setIsError(true);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getToken,
  };
};
