import React, { createContext, useEffect, useState } from "react";
import SendBird, { SendBirdInstance } from "sendbird";
import { useUserStore } from "store";

interface ISendBirdProvider {
  sb: SendBirdInstance;
  connecting: boolean;
  initialized: boolean;
  error: boolean;
}

interface OwnProps {
  children: React.ReactNode[] | React.ReactNode;
}

export const SB_INSTANCE = new SendBird({
  appId: process.env.REACT_APP_SENDBIRD_APP_ID || "",
});

export const SendBirdContext = createContext<ISendBirdProvider>(
  {} as ISendBirdProvider
);

export const SendBirdProvider: React.FC<OwnProps> = ({ children }) => {
  const [connecting, setConnecting] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(false);
  const { authorizedUser } = useUserStore();

  const userId = authorizedUser?.id;
  const sendbirdAccessToken = authorizedUser?.sendbirdAccessToken;

  useEffect(() => {
    const connect = async (id: string, token: string) => {
      setConnecting(true);
      setInitialized(false);
      setError(false);

      try {
        await SB_INSTANCE.connect(id, token);
        setInitialized(true);
      } catch (err) {
        setError(true);
      } finally {
        setConnecting(false);
      }
    };

    const disconnect = async () => {
      setConnecting(false);
      setError(false);

      try {
        await SB_INSTANCE.disconnect();
        setInitialized(false);
      } catch (err) {
        setError(true);
      }
    };

    if (
      !initialized &&
      userId !== undefined &&
      sendbirdAccessToken !== undefined
    ) {
      connect(userId, sendbirdAccessToken);
    } else if (initialized && userId === undefined) {
      disconnect();
    }
  }, [userId, sendbirdAccessToken, initialized]);

  const providerValue: ISendBirdProvider = {
    sb: SB_INSTANCE,
    connecting,
    initialized,
    error,
  };

  return (
    <SendBirdContext.Provider value={providerValue}>
      {children}
    </SendBirdContext.Provider>
  );
};
