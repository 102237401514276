import { useEffect, useState } from "react";

import { useEventsStore } from "store/events";
import { GetEventsFunction, GetEventsFunctionParams } from "types/functions";
import { BaseAsyncHook, BaseAsyncHookInput } from "types/hooks";
import { Event } from "types/entities";
import { parseAsyncHookConfiguration } from "utils";

interface UseGetEvents extends BaseAsyncHook {
  getEvents: GetEventsFunction;
  events: Event[];
}

interface UseGetEventsInput
  extends BaseAsyncHookInput,
    GetEventsFunctionParams {}

export const useGetEvents = (props?: UseGetEventsInput): UseGetEvents => {
  const config = parseAsyncHookConfiguration(props?.configuration);

  const _events = useEventsStore((state) => state.events);
  const _getEvents = useEventsStore((state) => state.getEvents);
  const [isLoading, setIsLoading] = useState<boolean>(
    config.preventNetworkRequest ? false : true
  );
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (config.preventNetworkRequest === false) getEvents(props);
  }, [config.preventNetworkRequest]);

  const getEvents = async (...args: Parameters<GetEventsFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const events = await _getEvents(...args);
      return events;
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getEvents,
    events: _events,
  };
};
