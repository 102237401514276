import {
  Alert,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { InputField, Text } from "components/common";
import Autocomplete from "react-google-autocomplete";
import { apiDateString } from "consts";
import { DATE_FIELDS, TABS_WITH_BOOLEAN_DESCRIPTION } from "consts/components";
import dayjs from "dayjs";
import {
  useGetVendorAvailableDatesInMonth,
  useGetVendorAvailableTimeslots,
} from "hooks";
import { useListingEditForm } from "hooks/components";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { ApiTimeslot } from "types/api";
import { DateString } from "types/common";
import { EditorFormTab, FormField, FormListing } from "types/components";
import { ListingMoreProperties } from "types/entities";
import { DateFormatter } from "utils";
import { numberToTimeString } from "utils/DateFormatter";

interface OwnProps {
  formData: FormListing;
  onChange: (data: FormListing) => void;
}

/**
 * Used for editing listing. This component is fully controllable.
 * Renders tabs and form for editing listing internals.
 */
export const ListingEditForm: React.FC<OwnProps> = ({ formData, onChange }) => {
  const {
    updateListing,
    // updateListingSchedule,
    generateFormTabs,
    mapTabToFormFields,
  } = useListingEditForm();
  // const { getVendorAvailableDatesInMonth, ...vendorDatesFetchingState } =
  //   useGetVendorAvailableDatesInMonth();
  // const { getVendorAvailableTimeslots, ...vendorTimeslotsFetchingState } =
  //   useGetVendorAvailableTimeslots();

  // const [datesAvailable, setDatesAvailable] = useState<
  //   Record<DateString, boolean> | undefined
  // >(); // @TODO: move to prop later
  // const [selectedScheduleDate, setSelectedScheduleDate] = useState<
  //   DateString | undefined
  // >();
  // Serves as a cache to limit number of requests made to the server
  // const [dateTimeslotsHashMap, setDateTimeslotsHashMap] = useState<
  //   Record<DateString, ApiTimeslot[]>
  // >({});
  const [activeEditorTab, setActiveEditorTab] = useState<number>(0);

  // This effect fires when new listing is picked for editing
  // useEffect(() => {
  //   (async () => {
  //     if (formData !== undefined) {
  //       // Reset errors
  //       vendorDatesFetchingState.clearError();
  //       vendorTimeslotsFetchingState.clearError();

  //       // Get selected schedule date if new active listing has a schedule
  //       const schedule = (() => {
  //         try {
  //           return formData["schedule"] ?? null;
  //         } catch (error) {
  //           return null;
  //         }
  //       })();

  //       // Automatically fetch Vendor's photo shoot dates that are available
  //       try {
  //         const dates = await getVendorAvailableDatesInMonth(
  //           schedule
  //             ? {
  //                 month: dayjs(schedule.date_specific, apiDateString).get(
  //                   "month"
  //                 ),
  //                 year: dayjs(schedule.date_specific, apiDateString).get(
  //                   "year"
  //                 ),
  //               }
  //             : {}
  //         );
  //         setDatesAvailable(dates);
  //       } catch (error) {
  //         console.log(error);
  //       }

  //       // If schedule exits then set else set today
  //       setSelectedScheduleDate(
  //         schedule
  //           ? schedule.date_specific
  //           : (dayjs().format(apiDateString) as DateString)
  //       );
  //     }
  //   })();
  // }, [formData]);

  // Fetch timeslots on new selected date
  // useEffect(() => {
  //   (async () => {
  //     if (selectedScheduleDate === undefined) return;

  //     const timeslotsInCache = dateTimeslotsHashMap[selectedScheduleDate];
  //     // Stop if timeslots exists
  //     if (timeslotsInCache?.length > 0) return;

  //     const timeslots = await getVendorAvailableTimeslots({
  //       dateSpecific: selectedScheduleDate,
  //     });
  //     setDateTimeslotsHashMap((curr) => ({
  //       ...curr,
  //       [selectedScheduleDate]: timeslots,
  //     }));
  //   })();
  // }, [selectedScheduleDate]);

  // Maps `FormField` to an UI element
  // Function is given `EditorFormTab` for context
  // Also, binds handlers to fields
  const formFieldToUiElement = (
    formData: FormListing,
    tab: EditorFormTab,
    [key, value]: FormField
  ): JSX.Element => {
    const { listing } = formData;

    // Partially applied function for better readability
    const updateField = (v: string | number | boolean | null) =>
      onChange(updateListing(formData, tab, [key, v]));

    const humanReadableLabel = _.startCase(key);

    const isComplementaryToBool =
      tab[1] !== undefined
        ? TABS_WITH_BOOLEAN_DESCRIPTION.includes(tab[1]) &&
          key.endsWith("Details")
        : false;

    const nestedParentObject =
      tab[1] !== undefined
        ? (listing[
            tab[1] as keyof ListingMoreProperties
          ] as unknown as ListingMoreProperties)
        : null;

    const nestedValue =
      nestedParentObject !== null
        ? nestedParentObject[
            key.replace("Details", "") as keyof typeof nestedParentObject
          ]
        : null;

    const disabled =
      isComplementaryToBool &&
      nestedValue !== undefined &&
      nestedValue !== null &&
      typeof nestedValue === "boolean" &&
      (nestedValue as unknown as boolean) === false;

    // Handle date fields
    const isDateField = DATE_FIELDS.includes(key);
    if (isDateField && typeof value === "string") {
      return (
        <InputField
          label={humanReadableLabel}
          value={value}
          onChange={(v) => (v.length > 0 ? updateField(v) : undefined)}
          type="date"
          fullWidth
        />
      );
    }

    // Handle Google autocomplete field
    if (key === "addressLine1") {
      return (
        <Autocomplete
          key={value?.toString()}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={(place) => {
            updateField(place.formatted_address);
          }}
          placeholder="Address Line 1"
          onChange={(e) => updateField(e.currentTarget.value)}
          defaultValue={value ? value.toString() : ""}
          language="en"
          className="google-autocomplete-input"
          options={{
            types: ["street_address"],
            componentRestrictions: { country: "us" },
          }}
        />
      );
    }

    // Handle basic types basing on `EditorFormTab` and `FormField`.
    switch (typeof value) {
      case "string":
        return (
          <InputField
            label={humanReadableLabel}
            value={value}
            onChange={updateField}
            disabled={disabled}
            fullWidth
          />
        );
      case "number":
        return (
          <InputField
            label={humanReadableLabel}
            value={value}
            type="number"
            onChange={(v) => updateField(parseInt(v))}
            fullWidth
          />
        );
      case "boolean":
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={(_) => updateField(!value)}
                />
              }
              label={humanReadableLabel}
            />
          </FormGroup>
        );

      default:
        return <></>;
    }
  };

  // const generateTimeslotsPicker = (
  //   formData: FormListing,
  //   availableTimeslots: ApiTimeslot[],
  //   selectedScheduleDate: DateString
  // ): JSX.Element => {
  //   const { schedule } = formData;

  //   return (
  //     <Grid container direction={"column"} spacing={2} paddingX={5}>
  //       <Grid container spacing={2}>
  //         {availableTimeslots.map((timeslot, i) => {
  //           const offset = new Date().getTimezoneOffset() * -1;
  //           const isPicked =
  //             schedule &&
  //             schedule.date_specific === selectedScheduleDate &&
  //             timeslot.minutes_end === schedule.minutes_end &&
  //             timeslot.minutes_start === schedule.minutes_start;
  //           // const inRange =
  //           //   timeslot.minutes_end + offset <= 60 * 24 &&
  //           //   timeslot.minutes_start + offset > 0;
  //           return (
  //             //   true && (
  //             <Grid key={i} item xs={6}>
  //               <Chip
  //                 style={{ width: "100%" }}
  //                 variant={isPicked ? "filled" : "outlined"}
  //                 size="medium"
  //                 color="info"
  //                 label={
  //                   <Text
  //                     fontSize={17}
  //                     fontWeight={400}
  //                     color={isPicked ? "white" : "black"}
  //                   >{`${numberToTimeString(
  //                     timeslot.minutes_start + offset
  //                   )} - ${numberToTimeString(
  //                     timeslot.minutes_end + offset
  //                   )}`}</Text>
  //                 }
  //                 onClick={(_) =>
  //                   onChange(
  //                     updateListingSchedule(formData, {
  //                       date_specific: selectedScheduleDate,
  //                       ...timeslot,
  //                     })
  //                   )
  //                 }
  //               />
  //             </Grid>
  //             //   )
  //           );
  //         })}
  //       </Grid>
  //     </Grid>
  //   );
  // };

  // Generating Edit Form composed
  const generateEditFormPage = (
    formData: FormListing,
    currentTab: EditorFormTab,
    // datesAvailable: Record<DateString, boolean> | undefined,
    // selectedScheduleDate: DateString | undefined
  ): JSX.Element[] => {
    const tabKey = currentTab[1];
    const isGeneralTab = tabKey === undefined;
    const formFields = mapTabToFormFields(currentTab, formData.listing);
    const uiElements = [
      ...formFields.map((field, i) => (
        <Grid key={i} item xs={2}>
          {formFieldToUiElement(formData, currentTab, field)}
        </Grid>
      )),
    ];

    // Don't append any new elements if function wasn't called for the general tab
    if (!isGeneralTab) return uiElements;

    // Append Event date time picker at the end of the `General` tab
    return [
      ...uiElements,
      // vendorTimeslotsFetchingState.error || vendorDatesFetchingState.error ? (
      //   <Grid key="calendar_error" item xs={12}>
      //     <Alert severity="error">Scheduling events is unavailable.</Alert>
      //   </Grid>
      // ) : (
      //   <Grid
      //     key="calendar"
      //     item
      //     flexDirection={"column"}
      //     marginTop={4}
      //     xs={12}
      //   >
      //     <Grid container direction={"column"} marginBottom={2}>
      //       <Text variant="h5">Schedule a Photo Shoot</Text>
      //       <Text variant="subtitle1">
      //         Pick time and date for a photo shoot event
      //       </Text>
      //     </Grid>
      //     <Grid item container direction={"row"}>
      //       <Grid item xs={6}>
      //         <Calendar
      //           key={selectedScheduleDate}
      //           showNeighboringMonth={false}
      //           locale="en"
      //           minDetail="year"
      //           maxDetail="month"
      //           className="schedule-react-calendar"
      //           minDate={dayjs().toDate()}
      //           tileClassName={({ date, view }) => {
      //             if (view === "month") {
      //               const { schedule } = formData;
      //               const now = dayjs();
      //               const parsedDate = DateFormatter.format(
      //                 date,
      //                 apiDateString
      //               ) as DateString;

      //               const isSelectedDate =
      //                 schedule && schedule.date_specific === parsedDate;
      //               const isSelectedForSchedule =
      //                 parsedDate === selectedScheduleDate;
      //               const isAvailable =
      //                 datesAvailable !== undefined &&
      //                 datesAvailable[parsedDate] &&
      //                 dayjs(date).isAfter(now.subtract(1, "day"));

      //               if (isSelectedDate) {
      //                 return "calendar-selected-date";
      //               }
      //               if (isSelectedForSchedule) {
      //                 return "calendar-focused-date";
      //               }
      //               if (isAvailable) {
      //                 return "calendar-available";
      //               }
      //             }
      //           }}
      //           value={
      //             selectedScheduleDate
      //               ? dayjs(selectedScheduleDate, apiDateString).toDate()
      //               : undefined
      //           }
      //           onChange={(date) =>
      //             date && !Array.isArray(date)
      //               ? setSelectedScheduleDate(
      //                   DateFormatter.format(date, apiDateString) as DateString
      //                 )
      //               : undefined
      //           }
      //           onActiveStartDateChange={async ({ activeStartDate }) => {
      //             if (activeStartDate) {
      //               const startDate = dayjs(activeStartDate);
      //               const dates = await getVendorAvailableDatesInMonth({
      //                 month: startDate.get("month"),
      //                 year: startDate.get("year"),
      //               });
      //               setDatesAvailable(dates);
      //             }
      //           }}
      //         />
      //       </Grid>
      //       <Grid item xs={6}>
      //         {selectedScheduleDate !== undefined &&
      //           dateTimeslotsHashMap[selectedScheduleDate] !== undefined &&
      //           generateTimeslotsPicker(
      //             formData,
      //             dateTimeslotsHashMap[selectedScheduleDate],
      //             selectedScheduleDate
      //           )}
      //       </Grid>
      //     </Grid>
      //   </Grid>
      // ),
    ];
  };

  return (
    <Stack spacing={3}>
      {/* <Snackbar
        open={
          vendorTimeslotsFetchingState.error !== false ||
          vendorDatesFetchingState.error !== false
        }
        autoHideDuration={6000}
        message="Scheduling events is unavailable. You won't be able to save your listings. PLease, try again later"
      /> */}

      <Tabs
        value={activeEditorTab}
        onChange={(_, value) => setActiveEditorTab(value)}
        aria-label="Edit Listing Form Tabs"
      >
        {generateFormTabs(formData.listing).map(([label], i) => (
          <Tab key={i} label={label} />
        ))}
      </Tabs>
      <Grid container spacing={2}>
        {generateEditFormPage(
          formData,
          generateFormTabs(formData.listing)[activeEditorTab],
          // datesAvailable,
          // selectedScheduleDate
        )}
      </Grid>
    </Stack>
  );
};
