import React, { useEffect, useState } from "react";
import { Button, Divider, Grid } from "@mui/material";
import {
  ErrorMessage,
  InputField,
  Text,
  LoadingIndicator,
  Table,
} from "components/common";
import "index.scss";
import { useGetListings, useSetWalkthroughUrl } from "hooks";
import { useListingsStore } from "store";
import { Listing } from "types/entities";
import { TableData, TableDataItem } from "types/components";
import { ViewWrapper } from "components/wrappers";
import { notification } from "antd";

const INITIAL_TABLE_DATA = {
  headers: [
    {
      title: "Address Line 1",
      key: "address_line_1",
    },
    {
      title: "Address Line 2",
      key: "address_line_2",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Zip",
      key: "zip",
    },
    {
      title: "Virtual Walkthrough URL",
      key: "virtual_walkthrough_url",
    },
  ],
  data: [],
};

export const ManageVirtualWalkthroughs: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { error: errorListings, getListings } = useGetListings();
  const {
    loading: loadingWalkthroughUrl,
    error: errorWalkthroughUrl,
    setWalkthroughUrl,
  } = useSetWalkthroughUrl();
  const { listings } = useListingsStore();
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [virtualWalkthroughUrl, setVirtualWalkthroughUrl] =
    useState<string>("");
  const [tableData, setTableData] = useState<TableData>({
    ...INITIAL_TABLE_DATA,
  });

  const handleConfirm = () => {
    setWalkthroughUrl({
      url: virtualWalkthroughUrl,
      listingId: selectedItem,
    }).then(() => getListings());
    api.success({
      message: `Successfully changed url`,
      placement: "bottomRight",
    });
  };
  const handleViewWalkthrough = () => {
    window.open(virtualWalkthroughUrl, "_blank")?.focus();
  };

  const handleRowClick = (item: TableDataItem) => {
    setSelectedItem(item.id as string);
    setVirtualWalkthroughUrl(
      (item.virtual_walkthrough_url as string | null) || ""
    );
  };

  useEffect(() => {
    getListings();
  }, []);

  useEffect(() => {
    // Map listings to table data
    const newTableData: TableData = { ...INITIAL_TABLE_DATA, data: [] };

    listings.forEach((listing) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = listing.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        tableItem[key] = listing[key];
      });
      newTableData.data.push(tableItem);
    });

    setTableData({ ...newTableData });
  }, [listings]);

  return (
    <ViewWrapper title="Manage Virtual Walkthrough URLs">
      {contextHolder}
      <Text>
        Please specify a virtual walkthrough URL in the field below, select a
        listing to connect it to and press confirm.
      </Text>
      <Text className="margin-top-10">
        You may view a currently selected listing's virtual walkthrough by
        clicking the "View current walkthrough" button.
      </Text>
      <Text className="margin-top-10">
        Note that only listings awaiting approval are displayed in the table
        below.
      </Text>

      <Divider className="margin-vertical-10" />

      <Grid container spacing={0.5} alignItems="center">
        <Grid item xs={2}>
          <Text>New virtual walkthrough URL:</Text>
        </Grid>
        <Grid item xs={4}>
          <InputField
            fullWidth
            value={virtualWalkthroughUrl}
            onChange={(text) => setVirtualWalkthroughUrl(text)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleConfirm}
            disabled={loadingWalkthroughUrl || !selectedItem}
          >
            <LoadingIndicator
              isVisible={loadingWalkthroughUrl}
              indicatorColor="white"
              size={24}
            />
            {!loadingWalkthroughUrl && "Confirm"}
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!virtualWalkthroughUrl}
            onClick={() => handleViewWalkthrough()}
          >
            View walkthrough
          </Button>
        </Grid>
      </Grid>

      <>{(errorListings || errorWalkthroughUrl) && <ErrorMessage />}</>

      <Table
        selectedRowId={selectedItem}
        onRowClick={handleRowClick}
        tableData={tableData}
      />
    </ViewWrapper>
  );
};
