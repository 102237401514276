import { MenuItem, TextField } from "@mui/material"
import { ErrorMessage } from "components/common/ErrorMessage"
import { LoadingIndicator } from "components/common/LoadingIndicator"
import { PaperSurface } from "components/common/PaperSurface"
import { PDFViewer } from "components/common/PDFViewer"
import { MAX_DOCUMENT_PREVIEW_HEIGHT } from "consts/dimensions"
import { useEffect, useState } from "react"
import { ListingDocument } from "types/entities"

type OwnProps = {
  documents: ListingDocument[],
  error: boolean,
  loading: boolean,
}

export const DocumentPreview: React.FC<OwnProps> = ({ documents, error, loading }) => {
  const [selectedDocument, setSelectedDocument] = useState<ListingDocument | null>(null);
  
  const handleSelectDocument = (fileName: string) => {
    const document = documents.find((document) => document.file_name === fileName);
    if (document) setSelectedDocument(document);
  }

  useEffect(() => {
    setSelectedDocument(null);
  }, [documents])

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <TextField
            select
            fullWidth
            label="Preview Document"
            value={selectedDocument?.file_name || ""}
            onChange={(e) => handleSelectDocument(e.target.value)}
          >
            {documents.map((item) => (
              <MenuItem key={item.file_name} value={item.file_name}>
                {item.file_name}
              </MenuItem>
            ))}
          </TextField>
          {(selectedDocument || error) && (
            <PaperSurface
              style={{
                padding: 10,
                marginTop: 10,
                maxHeight: MAX_DOCUMENT_PREVIEW_HEIGHT,
                overflowY: "auto",
              }}
            >
              {error && <ErrorMessage />}
              {selectedDocument && (
                <PDFViewer base64={selectedDocument?.data || ""} />
              )}
            </PaperSurface>
          )}
        </>
      )}
    </>
  );
}
