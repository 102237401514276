import { TableDataItem } from "types/components";

/**
 * Converts arbitrary object to `TableDataItem` using array of
 * visible keys.
 *
 * `B` is a subset or is equal to `A`.
 */
export const objectToTableDataItem = <
  A extends Record<string, unknown>,
  B extends TableDataItem
>(
  headerKeys: string[],
  obj: A,
  emptyCell: (key: string) => string = () => "-"
): B => {
  return headerKeys.reduce<B>(
    (acc, k) => ({ ...acc, [k]: obj[k] ?? emptyCell(k) }),
    {} as B
  );
};
