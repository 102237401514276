import _ from "lodash";

import {
  EditorFormTab,
  FormField,
  FormListing,
  // ListingSchedule,
} from "types/components";
import { DetailedListing, ListingMoreProperties } from "types/entities";
import { objectsKeys } from "utils";

/**
 * This hook is supposed to be used as a companion to
 * `ListingEditForm`.
 * Its purpose is to separate logic from UI as much as possible.
 */
export const useListingEditForm = () => {
  // Updates form listing
  const updateListing = (
    currentState: FormListing,
    [_, tabKey]: EditorFormTab,
    [fieldKey, newValue]: FormField
  ): FormListing => {
    try {
      const { listing } = currentState;
      const isNestedField = tabKey !== undefined;
      const newListing = (() => {
        if (isNestedField) {
          const nestedObject = listing[tabKey as keyof ListingMoreProperties];
          if (
            nestedObject === null ||
            Array.isArray(nestedObject) ||
            typeof nestedObject !== "object"
          )
            return null;

          nestedObject[fieldKey] = newValue as boolean | string;

          return {
            ...listing,
            [tabKey as keyof ListingMoreProperties]: nestedObject,
          };
        } else {
          return {
            ...listing,
            [fieldKey as keyof DetailedListing]: newValue,
          };
        }
      })();

      if (newListing === null) {
        console.log("Listing update failed");
        return currentState;
      }

      return { ...currentState, listing: newListing };
    } catch (error) {
      console.error(error);
      return currentState;
    }
  };

  // Update date for form listing
  // const updateListingSchedule = (
  //   currentState: FormListing,
  //   newSchedule: ListingSchedule
  // ): FormListing => {
  //   try {
  //     return {
  //       ...currentState,
  //       schedule: newSchedule,
  //     };
  //   } catch (error) {
  //     console.error(error);
  //     return currentState;
  //   }
  // };

  // Map tab to certain set of fields on `formListing`
  // Split `formListing` into subsets by `tab`
  const mapTabToFormFields = (
    tab: EditorFormTab,
    formListing: DetailedListing
  ): FormField[] => {
    const [_, tabKey] = tab;
    if (tabKey === undefined) {
      // A "General" tab
      // It will return all non-object fields
      return Object.entries(formListing).reduce<FormField[]>(
        (acc, [key, value]) =>
          typeof value !== "object" ? [...acc, [key, value]] : acc,
        []
      );
    } else {
      // `tabKey` is defined, therefore we search for nested fields
      const nestedObject = formListing[tabKey as keyof typeof formListing];
      if (nestedObject !== null && typeof nestedObject === "object") {
        return Object.entries(nestedObject);
      }
    }
    return [];
  };

  // Generate tabs for Editor Form basing on listing
  const generateFormTabs = (listing: DetailedListing) => {
    return listing
      ? objectsKeys(listing as unknown as Record<string, unknown>).reduce<
          EditorFormTab[]
        >(
          (acc, key) => [...acc, [_.startCase(key), key]],
          // Fixed tabs, always rendering no matter `listing` structure
          [["General", undefined]]
        )
      : [];
  };

  return {
    generateFormTabs,
    updateListing,
    // updateListingSchedule,
    mapTabToFormFields,
  };
};
