/**
 * Code is basically copied from https://mui.com/material-ui/react-drawer/#persistent-drawer
 *
 * There is very little changes from the original code from the tutorial.
 */

import * as React from "react";
import {
  styled,
  useTheme,
  Theme,
  CSSObject,
  makeStyles,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import ManageBulkGroupsIcon from "../../../assets/images/ManageBulkGroupsIcon.svg";

import VendorEventsIcons from "../../../assets/images/VendorEventsIcons.svg";
import ManageWalkthroughIcons from "../../../assets/images/ManageWalkthroughIcons.svg";
import ManageFilesIcons from "../../../assets/images/ManageFilesIcons.svg";
import ManageUsersInactive from "../../../assets/images/ManageUsersInactive.svg";
import ManageListingsIcons from "../../../assets/images/ManageListingsIcons.svg";
import ManageTitlesIcons from "../../../assets/images/ManageTitlesIcons.svg";
import VendorZipCodesIcon from "../../../assets/images/VendorZipCodesIcon.svg";
import BulkAddListingsIcon from "../../../assets/images/BulkAddListingsIcon.svg";
import MessagesIcon from "../../../assets/images/MessagesIcon.svg";
import SettingsIcon from "../../../assets/images/SettingsIcon.svg";

import ChevronLeftIcon from ".././../../assets/images/ChevronLeftIcon.svg";
import ChevronRightIcon from ".././../../assets/images/ChevronRightIcon.svg";

import logo from ".././../../assets/images/logo.svg";
import logoOpen from ".././../../assets/images/logoOpen.svg";

import OtherHousesTwoToneIcon from "@mui/icons-material/OtherHousesTwoTone";
import HolidayVillageTwoToneIcon from "@mui/icons-material/HolidayVillageTwoTone";
import WorkspacesTwoToneIcon from "@mui/icons-material/WorkspacesTwoTone";
import ContentPasteTwoToneIcon from "@mui/icons-material/ContentPasteTwoTone";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import SettingsApplicationsTwoToneIcon from "@mui/icons-material/SettingsApplicationsTwoTone";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeftIcon";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PasswordIcon from "@mui/icons-material/Password";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import { Text, Link } from "components/common";
import { AUTH_ROUTER_PATHS, AUTH_ROUTER_ROLE_PATHS } from "types/navigation";
import { useGetMe, useSignOut } from "hooks";
import VrpanoTwoToneIcon from "@mui/icons-material/VrpanoTwoTone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HandshakeIcon from "@mui/icons-material/Handshake";
import MessageIcon from "@mui/icons-material/Message";
import PostAddIcon from "@mui/icons-material/PostAdd";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface INavigationButton {
  icon: React.ReactNode;
  isOpen: boolean;
  label: string;
  onClick?: () => void;
  to?: string;
}

const NavigationButton: React.FC<INavigationButton> = ({
  icon,
  isOpen,
  label,
  onClick,
  to,
}) => {
  const Wrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    if (to) {
      return <Link path={to}>{children}</Link>;
    } else {
      return <div onClick={onClick}>{children}</div>;
    }
  };

  return (
    <Wrapper>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: isOpen ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isOpen ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "13px",
                fontFamily: "PlusJakartaSans-Regular",
              }}
            >
              {label}
            </div>
          }
          sx={{ opacity: isOpen ? 1 : 0, textDecoration: "none" }}
        />
      </ListItemButton>
      <Divider />
    </Wrapper>
  );
};

const ROUTE_ICONS = [
  {
    icon: <img src={ManageUsersInactive} alt="Manage users icon" />,
    label: "Manage users",
    path: AUTH_ROUTER_PATHS.MANAGE_USERS,
  },
  {
    icon: <img src={ManageListingsIcons} alt="Manage users icon" />,
    label: "Manage listings",
    path: AUTH_ROUTER_PATHS.MANAGE_LISTINGS,
  },
  {
    icon: <img src={BulkAddListingsIcon} alt="Manage users icon" />,
    label: "Bulk add listings",
    path: AUTH_ROUTER_PATHS.BULK_ADD_LISTINGS,
  },
  {
    icon: <img src={ManageBulkGroupsIcon} alt="Manage users icon" />,
    label: "Manage bulk groups",
    path: AUTH_ROUTER_PATHS.MANAGE_BULK_GROUPS,
  },
  {
    icon: <img src={VendorZipCodesIcon} alt="Manage users icon" />,
    label: "Vendor zipcodes",
    path: AUTH_ROUTER_PATHS.VENDOR_ZIPCODES,
  },
  {
    icon: <img src={VendorEventsIcons} alt="Manage users icon" />,
    label: "Vendor events",
    path: AUTH_ROUTER_PATHS.VENDOR_EVENTS,
  },
  {
    icon: <img src={ManageFilesIcons} alt="Manage users icon" />,
    label: "Manage files",
    path: AUTH_ROUTER_PATHS.MANAGE_FILES,
  },
  {
    icon: <img src={ManageWalkthroughIcons} alt="Manage users icon" />,
    label: "Manage Walkthrough",
    path: AUTH_ROUTER_PATHS.MANAGE_VIRTUAL_WALKTHROUGHS,
  },
  {
    icon: <img src={ManageTitlesIcons} alt="Manage users icon" />,
    label: "Manage titles",
    path: AUTH_ROUTER_PATHS.MANAGE_TITLES,
  },
  {
    icon: <PostAddIcon />,
    label: "Generate documents",
    path: AUTH_ROUTER_PATHS.GENERATE_DOCUMENTS,
  },
  {
    icon: <img src={SettingsIcon} alt="Manage users icon" />,
    label: "Settings",
    path: AUTH_ROUTER_PATHS.APP_SETTINGS,
  },
  {
    icon: <PasswordIcon />,
    label: "Change password",
    path: AUTH_ROUTER_PATHS.CHANGE_PASSWORD,
  },
  {
    icon: <img src={MessagesIcon} alt="Manage users icon" />,
    label: "Messages",
    path: AUTH_ROUTER_PATHS.MESSAGES,
  },
];

interface OwnProps {
  children: React.ReactNode;
}

type TAuthenticatedView = OwnProps;

export const AuthenticatedView: React.FC<TAuthenticatedView> = ({
  children,
}) => {
  const { signOut } = useSignOut();
  const { user } = useGetMe();
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const generateNavButtonList = () => {
    if (!user) return null;

    const { isAdmin, isListingSpecialist, isVendor } = user;
    let availablePaths: string[] = [];

    if (isAdmin) availablePaths = [...AUTH_ROUTER_ROLE_PATHS.ADMINISTRATOR];
    if (isListingSpecialist)
      availablePaths = [
        ...availablePaths,
        ...AUTH_ROUTER_ROLE_PATHS.LISTING_SPECIALIST,
      ];
    if (isVendor)
      availablePaths = [...availablePaths, ...AUTH_ROUTER_ROLE_PATHS.VENDOR];

    // Get rid of duplicates
    availablePaths = [...new Set(availablePaths)];

    const roleFilteredRoutes = ROUTE_ICONS.filter((route) =>
      availablePaths.includes(route.path)
    );

    return roleFilteredRoutes.map(({ path, icon, label }) => (
      <NavigationButton
        icon={icon}
        isOpen={open}
        label={label}
        to={path}
        key={path}
      />
    ));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Text variant="h6" color="#fff">
            Admin panel
          </Text>
        </Toolbar>
      </AppBar> */}
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "black", // Set background color to black
            color: "white", // Optional: set text color to white for better contrast
            // width: open ? "15%" : "4%",
          },
        }}
      >
        {open ? (
          <div>
            <img
              src={logo}
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                height: "65px",
              }}
            />
          </div>
        ) : (
          <div>
            <img
              src={logoOpen}
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                height: "80px",
              }}
            />
          </div>
        )}
        <DrawerHeader style={{ marginBottom: "-50px" }}>
          <IconButton
            color="inherit"
            aria-label="toggle drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 0.7,
            }}
          >
            {open ? (
              <img
                src={ChevronLeftIcon}
                style={{
                  position: "absolute",
                  marginLeft: "30px",
                  marginTop: "-30px",
                  height: "30px",
                  zIndex: 9999,
                }}
              />
            ) : (
              <img
                src={ChevronRightIcon}
                style={{
                  position: "absolute",
                  marginLeft: "30px",
                  marginTop: "-30px",
                  height: "30px",
                }}
              />
            )}
          </IconButton>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )} */}
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton> */}
          {/* </IconButton> */}
        </DrawerHeader>
        <div
          style={{
            backgroundColor: "white",
            width: open ? "80%" : "35%",
            alignSelf: "center",
          }}
        >
          <Divider variant="middle" />
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          {" "}
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              {generateNavButtonList()}
            </ListItem>
          </List>
          <List>
            <NavigationButton
              icon={<LogoutTwoToneIcon />}
              isOpen={open}
              label="Logout"
              onClick={() => {
                signOut();
              }}
            />
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};
