import { useState } from "react";

import { useListingsStore } from "store";
import { GetListingFunction, } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetListing extends BaseAsyncHook {
  getListing: GetListingFunction;
}

export const useGetListing = (): UseGetListing => {
  const _getListing = useListingsStore((state) => state.getListing);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getListing = async (...args: Parameters<GetListingFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const listing = await _getListing(args[0]);
      return listing;
    } catch (error) {
      setIsError(true);
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getListing,
  };
};
