import { SendBirdProvider } from ".";
import React from "react";

type OwnProps = {
  children: React.ReactNode[] | React.ReactNode;
};

export const Providers: React.FC<OwnProps> = ({ children }) => (
  <SendBirdProvider>{children}</SendBirdProvider>
);
