import { useEffect, useState } from "react";

import { useUserStore } from "store";
import {
  GetVendorsWithTimeslotsFunction,
  GetVendorsWithTimeslotsFunctionParams,
} from "types/functions";
import { BaseAsyncHook, BaseAsyncHookInput } from "types/hooks";
import { EntityIdTimeslotsHashMap } from "types/store";
import { parseAsyncHookConfiguration } from "utils";

interface UseGetVendorsWithTimeslots extends BaseAsyncHook {
  loading: boolean;
  error: boolean;
  vendorIdDateTimeslotsHashMap: EntityIdTimeslotsHashMap;
}

interface UseGetEventsInput
  extends BaseAsyncHookInput,
    GetVendorsWithTimeslotsFunctionParams {}

/**
 * Retrieve Vendors along with their timeslots for a specific date.
 */
export const useGetVendorsWithTimeslots = (
  props: UseGetEventsInput
): UseGetVendorsWithTimeslots => {
  const config = parseAsyncHookConfiguration(props?.configuration);

  const { _getVendorsWithTimeslots, _vendorIdDateTimeslotsHashMap } =
    useUserStore((state) => ({
      _getVendorsWithTimeslots: state.getVendorsWithTimeslots,
      _vendorIdDateTimeslotsHashMap: state.vendorIdDateTimeslotsHashMap,
    }));

  const [isLoading, setIsLoading] = useState<boolean>(
    config.preventNetworkRequest ? false : true
  );
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const onLoad = async (
      ...args: Parameters<GetVendorsWithTimeslotsFunction>
    ) => {
      try {
        setIsError(false);
        setIsLoading(true);
        await _getVendorsWithTimeslots(...args);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (config.preventNetworkRequest === false) onLoad(props);
  }, [
    _getVendorsWithTimeslots,
    config.preventNetworkRequest,
    props.date,
    props.role,
  ]);

  return {
    error: isError,
    loading: isLoading,
    vendorIdDateTimeslotsHashMap: _vendorIdDateTimeslotsHashMap,
  };
};
