import React from "react";

import { AuthenticationWrapper } from "components/wrappers";
import { Providers } from "providers";

export const App: React.FC = () => {
  return (
    <Providers>
      <AuthenticationWrapper />
    </Providers>
  );
};

export default App;
