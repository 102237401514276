import { ApiAppPrice } from "types/api";
import { AppPrices } from "types/entities";

export const serializeAppPrices = (prices: ApiAppPrice[]): AppPrices => {
  const serializedPrices: AppPrices = {};
  prices.forEach((price) => {
    serializedPrices[price.type] = {
      id: price.id,
      price: price.price,
    };
  });
  return serializedPrices;
};
