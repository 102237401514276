export enum ListingDocumentStatus {
  PENDING = "pending",
  DECLINED = "declined",
  APPROVED = "approved",
}

export enum ListingDocumentType {
  INSPECTION = "inspection",
  TITLE = "title",
  DISCLOSURE = "disclosure",
  SURVEY = "survey",
  OTHER = "other",
}

interface EversignDocument {
  id: string;
  document_id: string;
  eversign_hash: string;
  is_sandbox: boolean;
}

export interface ListingDocument {
  data: string; //base64 encoded string (data:pdf;base64,{CONTENT})
  file_name: string;
  url: string;
  status: ListingDocumentStatus;
  eversign_document: null | EversignDocument;
  type: string; // @TODO: type
}
