import create from "zustand";

import { UploadService } from "services";
import {
  UploadDocsFunction,
  UploadPhotosFunction,
} from "types/functions";

interface UploadStore {
  uploadDocs: UploadDocsFunction;
  uploadPhotos: UploadPhotosFunction;
}

export const useUploadStore = create<UploadStore>((set) => ({
  uploadDocs: async (...args: Parameters<UploadDocsFunction>) => {
    try {
      return await UploadService.uploadDocs(...args);
    } catch (error) {
      throw error;
    }
  },
  uploadPhotos: async (...args: Parameters<UploadPhotosFunction>) => {
    try {
      return await UploadService.uploadPhotos(...args);
    } catch (error) {
      throw error;
    }
  },
}));
