import { useState } from "react";

import { useListingsStore } from "store";
import { GetListingSellerFunction, } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetListingSeller extends BaseAsyncHook {
  getListingSeller: GetListingSellerFunction;
}

export const useGetListingSeller = (): UseGetListingSeller => {
  const _getListingSeller = useListingsStore((state) => state.getListingSeller);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getListingSeller = async (...args: Parameters<GetListingSellerFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const listingSeller = await _getListingSeller(args[0]);
      return listingSeller;
    } catch (error) {
      setIsError(true);
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getListingSeller,
  };
};
