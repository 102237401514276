import dayjs from "dayjs";
import { DateString } from "types/common";
import { DateFormatter } from "utils";

/**
 * Format date object to fixed date string.
 */
export const toDateString = (date: Date | string): DateString => {
  let _date = date;
  if (typeof _date === "string") _date = dayjs(_date).toDate();

  return DateFormatter.format(date, "MM-DD-YYYY") as DateString;
};
