import { Box, Container, Stack } from "@mui/material";
import React, { FormEvent, useState } from "react";

import {
  Button,
  InputField,
  Link,
  PaperSurface,
  Text,
} from "components/common";
import { useSignIn } from "hooks";
import { UNATH_ROUTER_PATHS } from "types/navigation";

interface OwnProps {}

type TSignIn = OwnProps;

export const SignIn: React.FC<TSignIn> = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { loading: signInLoading, signIn } = useSignIn();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email.length > 0 && password.length > 0) {
      signIn(email, password);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Stack alignItems="center">
          <Text variant="h4" fontWeight={500} sx={{ marginBottom: "20px" }}>
            Sign in
          </Text>
          <PaperSurface
            elevation={3}
            sx={{
              padding: "45px 40px",
              width: "100%",
            }}
          >
            <form onSubmit={handleFormSubmit}>
              <Stack spacing={2}>
                <InputField
                  label="Email"
                  type="email"
                  variant="outlined"
                  required
                  value={email}
                  onChange={setEmail}
                />
                <InputField
                  label="Password"
                  type="password"
                  variant="outlined"
                  required
                  value={password}
                  onChange={setPassword}
                />
                <Button
                  variant="contained"
                  type="submit"
                  disabled={signInLoading}
                >
                  Continue
                </Button>
              </Stack>
            </form>
          </PaperSurface>
          <Box sx={{ marginTop: "15px" }}>
            <Link path={UNATH_ROUTER_PATHS.RESET_PASSWORD}>
              <Text>I forgot my password</Text>
            </Link>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
