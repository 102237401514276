import { useState } from "react";

import { useTitleStore } from "store";
import { GetTitleDocumentFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetTitleDocument extends BaseAsyncHook {
  getTitleDocument: GetTitleDocumentFunction;
}

export const useGetTitleDocument = (): UseGetTitleDocument => {
  const _stateFunc = useTitleStore(
    (state) => state.getTitleDocument
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getTitleDocument = async (
    ...args: Parameters<GetTitleDocumentFunction>
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const titleDocument = await _stateFunc(args[0]);
      return titleDocument;
    } catch (error) {
      setIsError(true);
      return '';
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getTitleDocument,
  };
};
