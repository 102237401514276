import { Button, Grid, IconButton, Stack } from "@mui/material";
import { CardModal, ClickableImage, ErrorMessage, LoadingIndicator, Text } from "components/common";
import { useGetPhotos, useRemovePhoto, useSetPhotos } from "hooks";
import { useEffect, useRef, useState } from "react";
import { usePhotoStore } from "store";
import { DetailedListing, Photo } from "types/entities";
import { isDictEmpty } from "utils";
import DeleteIcon from '@mui/icons-material/Delete';
// @types/react-reorder doesn't exist - we need to silence typescript with @ts-ignore
// @ts-ignore
import ReactReorder from "react-reorder";
import { DIMENSIONS } from "consts";

interface OwnProps {
  listing: DetailedListing | {};
  reloadListing: () => void;
}

const INITIAL_MODAL_STATE = {
  src: '',
  isOpen: false,
  fileName: '',
}

export const ManageListingsPhotoSelector: React.FC<OwnProps> = ({
  listing = {},
  reloadListing,
}) => {
  // Axios source used for cancelling photo downloads
  const abortControllerRef = useRef<AbortController>(
    new AbortController()
  );
  const CLICKABLE_IMAGE_DIMENSIONS = {
    WIDTH: 300,
    HEIGHT: 300,
  };
  const { getPhotos, loading: loadingGetPhotos } = useGetPhotos();
  const { setPhotos, loading: loadingSetPhotos } = useSetPhotos();
  const { removePhoto, loading: loadingRemovePhoto, error: errorRemovePhoto } = useRemovePhoto();
  const { photos } = usePhotoStore();
  const [orderedPhotos, setOrderedPhotos] = useState<Photo[]>([]);
  const [modalState, setModalState] = useState({ ...INITIAL_MODAL_STATE });

  const fetchData = (listing: DetailedListing) => {
    getPhotos({ listingId: listing.id, signal: abortControllerRef.current.signal });
  };

  useEffect(() => {
    if (!isDictEmpty(listing)) {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      fetchData(listing as DetailedListing)
    }
  }, [listing]);

  useEffect(() => {
    // Map photos according to photo_urls currently set on listing
    if (!isDictEmpty(listing)) {
      const photoUrls = (listing as DetailedListing)?.photo_urls;
      const newOrderedPhotos: Photo[] = [];
      photoUrls.forEach((url) => {
        const foundPhoto: Photo | undefined = photos.find(
          (photo) => photo.url === url
        );
        if (foundPhoto) newOrderedPhotos.push(foundPhoto);
      });
      setOrderedPhotos([...newOrderedPhotos]);
    }
  }, [photos]);

  useEffect(() => {
    if (!errorRemovePhoto && !loadingRemovePhoto && modalState.isOpen) {
      setModalState({ ...INITIAL_MODAL_STATE });
      fetchData(listing as DetailedListing);
    }
  }, [errorRemovePhoto, loadingRemovePhoto])

  const handleAddToOrderedPhotos = (photo: Photo) => {
    // Do not allow duplicates
    if (orderedPhotos.some((orderedPhoto) => orderedPhoto.file_name === photo.file_name)) return;
    setOrderedPhotos([...orderedPhotos, photo]);
  };

  const handleRemoveFromOrderedPhotos = (photo: Photo) => {
    const newOrderedPhotos = orderedPhotos.filter(
      (orderedPhoto) =>
        orderedPhoto.file_name !== photo.file_name &&
        orderedPhoto.url !== photo.url
    );
    setOrderedPhotos([...newOrderedPhotos]);
  };

  const renderPhotoSelectionList = () => {
    // Filter out orderedPhotos from available options
    const filteredPhotos = photos.filter((photo) => !orderedPhotos.includes(photo))
    return <>
      {filteredPhotos.map((photo) => (
        <div
          style={{
            textAlign: "center",
            position: "relative",
            width: CLICKABLE_IMAGE_DIMENSIONS.WIDTH,
            height: CLICKABLE_IMAGE_DIMENSIONS.HEIGHT,
            margin: "auto",
          }}
          key={photo.file_name}
        >
          <div className="delete-icon-container">
            <IconButton onClick={() => setModalState({ isOpen: true, src: photo.data, fileName: photo.file_name })}>
              <DeleteIcon color="error" fontSize="large"/>
            </IconButton>
          </div>
          <ClickableImage
            key={photo.url}
            imageProps={{
              src: photo.data,
              style: {
                width: CLICKABLE_IMAGE_DIMENSIONS.WIDTH,
                height: CLICKABLE_IMAGE_DIMENSIONS.HEIGHT,
                objectFit: "cover",
              },
            }}
            onClick={() => handleAddToOrderedPhotos(photo)}
          />
        </div>
      ))}
    </>
  };

  const renderOrderedPhotosList = () => (
    <ReactReorder
      reorderId="reorder-elem-container"
      draggedClassName="reorder-elem-dragged"
      onReorder={handleReorder}
    >
      {orderedPhotos.map((photo) => (
        <div
          style={{
            textAlign: "center",
            position: "relative",
            width: CLICKABLE_IMAGE_DIMENSIONS.WIDTH,
            height: CLICKABLE_IMAGE_DIMENSIONS.HEIGHT,
            margin: "auto",
          }}
          key={photo.file_name}
        >
          <div className="delete-icon-container">
            <IconButton onClick={() => setModalState({ isOpen: true, src: photo.data, fileName: photo.file_name })}>
              <DeleteIcon color="error" fontSize="large"/>
            </IconButton>
          </div>
            <ClickableImage
              key={photo.url}
              imageProps={{
                src: photo.data,
                style: {
                  width: CLICKABLE_IMAGE_DIMENSIONS.WIDTH,
                  height: CLICKABLE_IMAGE_DIMENSIONS.HEIGHT,
                  objectFit: "cover",
                },
              }}
              onClick={() => handleRemoveFromOrderedPhotos(photo)}
            />
          </div>
      ))}
    </ReactReorder>
  );

  const handleReorder = (
    event: MouseEvent,
    prevIndex: number,
    nextIndex: number
  ) => {
    // Switch places of the elements
    const newOrderedPhotos = [...orderedPhotos];
    const draggedPhoto = newOrderedPhotos[prevIndex];
    newOrderedPhotos[prevIndex] = newOrderedPhotos[nextIndex];
    newOrderedPhotos[nextIndex] = draggedPhoto;
    setOrderedPhotos([...newOrderedPhotos]);
  };

  const handleResetSelection = () => setOrderedPhotos([]);

  const handleSubmitSelection = () => {
    // Backend expects a list of file names
    if (!isDictEmpty(listing)) {
      const fileNames = orderedPhotos.map(
        (orderedPhoto) => orderedPhoto.file_name
      );
      setPhotos({ listingId: (listing as DetailedListing).id, fileNames }).then(reloadListing);
    }
  };

  const handleRemovePhoto = (fileName: string) => {
    // fileName is of the following format:
    // {photoId}.{listingId}.{file extension}
    const [photoId, ...rest] = fileName.split('.')
    removePhoto({ photoId });
  }

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <Text variant="h5" fontWeight={600} marginBottom="20px">
            Select Photos
          </Text>
        </Grid>
        <Grid item xs={6}>
          <Text variant="h5" fontWeight={600} marginBottom="20px">
            Rearrange Photos
          </Text>
        </Grid>
        <Grid
          item
          xs={6}
          maxHeight={DIMENSIONS.MAX_GRID_ITEM_HEIGHT}
          style={{ overflowY: "auto" }}
        >
          {loadingGetPhotos ? (
            <LoadingIndicator size={24} />
          ) : (
            renderPhotoSelectionList()
          )}
        </Grid>
        <Grid
          item
          xs={6}
          maxHeight={DIMENSIONS.MAX_GRID_ITEM_HEIGHT}
          style={{ overflowY: "auto" }}
        >
        {loadingGetPhotos ? (
            <LoadingIndicator size={24} />
          ) : (
            renderOrderedPhotosList()
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleSubmitSelection}
            disabled={loadingSetPhotos}
          >
            {loadingSetPhotos ? (
              <LoadingIndicator size={24} indicatorColor="white" />
            ) : (
              "Submit"
            )}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleResetSelection}
            disabled={orderedPhotos.length === 0}
          >
            Reset Selection
          </Button>
        </Grid>
      </Grid>
      <CardModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ ...INITIAL_MODAL_STATE })}
      >
        <Stack spacing={2} direction='column'>
            <Text variant='h6'>
                Are you sure you want to remove this photo from the database?
            </Text>
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <img
                alt='Selected img'
                src={modalState.src}
                style={{
                  width: CLICKABLE_IMAGE_DIMENSIONS.WIDTH,
                  height: CLICKABLE_IMAGE_DIMENSIONS.HEIGHT,
                  objectFit: "cover",
                }}
              />
            </div>
            {errorRemovePhoto && <ErrorMessage />}
            <Button
              variant="contained"
              type="submit"
              disabled={loadingRemovePhoto}
              onClick={() => handleRemovePhoto(modalState.fileName)}
            >
              {loadingRemovePhoto ? <LoadingIndicator size={24} indicatorColor="white" /> : 'Confirm'}
            </Button>
        </Stack>
      </CardModal>
    </>
  );
};
