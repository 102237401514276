import { useState } from "react";

import { useTitleStore } from "store";
import { GetTitleStatusFunction, } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetTitleStatus extends BaseAsyncHook {
  getTitleStatus: GetTitleStatusFunction;
}

export const useGetTitleStatus = (): UseGetTitleStatus => {
  const _stateFunc = useTitleStore((state) => state.getTitleStatus);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getTitleStatus = async (...args: Parameters<GetTitleStatusFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const titleStatus = await _stateFunc(args[0]);
      return titleStatus;
    } catch (error) {
      setIsError(true);
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getTitleStatus,
  };
};
