import { BaseTextFieldProps, TextField } from "@mui/material";
import React from "react";

interface OwnProps extends BaseTextFieldProps {
  onChange: (value: string) => void;
}

type TInputField = OwnProps;

export const InputField: React.FC<TInputField> = ({ onChange, ...props }) => {
  return (
    <TextField
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.target.value)
      }
      {...props}
    />
  );
};
