import { useState } from "react";

import { usePhotoStore } from "store";
import { SetPhotosFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseSetPhotos extends BaseAsyncHook {
  setPhotos: SetPhotosFunction;
}

export const useSetPhotos = (): UseSetPhotos => {
  const _storeFunc = usePhotoStore((state) => state.setPhotos);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const setPhotos = async (...args: Parameters<SetPhotosFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const photos = await _storeFunc(args[0]);
      return photos;
    } catch (error) {
      setIsError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    setPhotos,
  };
};
