import create from "zustand";

import {
  BulkApproveListingsFunction,
  BulkApproveListingsFunctionParams,
  GetBulkGroupFunction,
  GetBulkGroupFunctionParams,
  GetBulkGroupsFunction,
  GetListingFunction,
  GetListingSellerFunction,
  GetListingsFunction,
  RemoveListingFunction,
  UpdateListingFunction,
  UploadBulkListingDocFunction,
  UploadBulkListingFunction,
} from "types/functions";
import { DetailedListing, Listing, ListingSeller } from "types/entities";
import { ListingService } from "services";

interface ListingStore {
  listings: Listing[];
  listing: DetailedListing | {};
  listingSeller: ListingSeller | {};
  getListings: GetListingsFunction;
  getListing: GetListingFunction;
  updateListing: UpdateListingFunction;
  getListingSeller: GetListingSellerFunction;
  removeListing: RemoveListingFunction;
  uploadBulkListingDoc: UploadBulkListingDocFunction;
  uploadBulkListing: UploadBulkListingFunction;
  getBulkGroups: GetBulkGroupsFunction;
  getBulkGroup: GetBulkGroupFunction;
  bulkApproveListings: BulkApproveListingsFunction;
}

export const useListingsStore = create<ListingStore>((set) => ({
  listings: [],
  listing: {},
  listingSeller: {},
  getListings: async (...args: Parameters<GetListingsFunction>) => {
    try {
      const listings = await ListingService.getListings(...args);
      set({ listings });
      return listings;
    } catch (error) {
      throw error;
    }
  },
  getListing: async (...args: Parameters<GetListingFunction>) => {
    try {
      const listing = await ListingService.getListing(...args);
      set({ listing });

      return listing;
    } catch (error) {
      throw error;
    }
  },
  updateListing: async (...args: Parameters<UpdateListingFunction>) => {
    try {
      const listing = await ListingService.updateListing(...args);
      set({ listing });

      return listing;
    } catch (error) {
      throw error;
    }
  },
  getListingSeller: async (...args: Parameters<GetListingSellerFunction>) => {
    try {
      const listingSeller = await ListingService.getListingSeller(...args);
      set({ listingSeller });

      return listingSeller;
    } catch (error) {
      throw error;
    }
  },
  removeListing: async (...args: Parameters<RemoveListingFunction>) => {
    try {
      const response = await ListingService.removeListing(...args);

      return response;
    } catch (error) {
      throw error;
    }
  },
  uploadBulkListingDoc: async (
    ...args: Parameters<UploadBulkListingDocFunction>
  ) => {
    try {
      const response = await ListingService.uploadBulkListingDoc(...args);

      return response;
    } catch (error) {
      throw error;
    }
  },
  uploadBulkListing: async (...args: Parameters<UploadBulkListingFunction>) => {
    try {
      const response = await ListingService.uploadBulkListing(...args);

      return response;
    } catch (error) {
      throw error;
    }
  },
  getBulkGroups: async () => {
    try {
      const response = await ListingService.getBulkGroups();

      return response;
    } catch (error) {
      throw error;
    }
  },
  getBulkGroup: async (params: GetBulkGroupFunctionParams) => {
    try {
      const response = await ListingService.getBulkGroup(params);

      return response;
    } catch (error) {
      throw error;
    }
  },
  bulkApproveListings: async (params: BulkApproveListingsFunctionParams) => {
    try {
      const response = await ListingService.bulkApproveListings(params);

      return response;
    } catch (error) {
      throw error;
    }
  },
}));
