import { Button, MenuItem, Stack } from "@mui/material";
import {
  CardModal,
  ErrorMessage,
  InputField,
  LoadingIndicator,
  Text,
} from "components/common";
import { useState } from "react";
import { Buffer } from "buffer";
import { UploadTitleDocumentPayload } from "types/functions";
import { useUploadTitleDocument } from "hooks";

interface OwnProps {
  isOpen: boolean;
  onClose: (success: boolean) => void;
  listingId: string;
  transactionId: string;
}

const DOCUMENT_TYPE_OPTIONS = [
  {
    value: "LOAN_APPLICATION",
    label: "Loan application",
  },
  {
    value: "Purchase Agreement",
    label: "Purchase agreement",
  },
  {
    value: "OPENING_INSTRUCTIONS",
    label: "Opening instructions",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

const INITIAL_FORM: UploadTitleDocumentPayload = {
  name: "",
  type: "Purchase Agreement",
  data: "",
  mimeType: "",
  note: "",
};

export const TitleUploadDocumentModal: React.FC<OwnProps> = ({
  isOpen,
  onClose,
  listingId,
  transactionId,
}) => {
  const {
    uploadTitleDocument,
    loading: loadingUploadDocument,
    error: errorUploadDocument,
  } = useUploadTitleDocument();
  const [form, setForm] = useState({ ...INITIAL_FORM });
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleChange = (val: string, key: keyof UploadTitleDocumentPayload) => {
    setForm({ ...form, [key]: val });
  };

  const handleChangeData = async (files: FileList | null) => {
    if (!files) {
      setSelectedFileName("");
      setForm({ ...form, data: "" });
    } else {
      const file = files[0];
      const arrayBuffer = await file.arrayBuffer();
      const base64 = Buffer.from(arrayBuffer).toString("base64");
      setSelectedFileName(file.name);
      setForm({ ...form, data: base64, mimeType: file.type });
    }
  };

  const handleUploadDocument = async () => {
    uploadTitleDocument({ listingId, transactionId, payload: form }).then(
      (isSuccess) => {
        if (isSuccess) onClose(isSuccess);
      }
    );
  };

  return (
    <CardModal isOpen={isOpen} onClose={() => onClose(false)}>
      <Stack spacing={2}>
        <Text variant="h6">Upload document</Text>

        <InputField
          fullWidth
          onChange={(val) => handleChange(val, "name")}
          label="Document name"
          value={form.name}
        />

        <InputField
          select
          fullWidth
          onChange={(val) => handleChange(val, "type")}
          label="Document type"
          value={form.type}
        >
          {DOCUMENT_TYPE_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </InputField>

        <Button component="label" variant="contained" disabled={false}>
          {selectedFileName
            ? `Selected document: ${selectedFileName}`
            : "Select document"}
          <input
            type="file"
            hidden
            value={""}
            onChange={(e) => handleChangeData(e.target.files)}
          />
        </Button>

        <InputField
          fullWidth
          onChange={(val) => handleChange(val, "note")}
          label="Additional notes"
          value={form.note}
        />

        <Button
          variant="contained"
          type="submit"
          disabled={loadingUploadDocument}
          onClick={handleUploadDocument}
        >
          {loadingUploadDocument ? (
            <LoadingIndicator size={24} indicatorColor="white" />
          ) : (
            "Confirm"
          )}
        </Button>
        {errorUploadDocument && <ErrorMessage />}
      </Stack>
    </CardModal>
  );
};
