import { useState } from "react";

import { useListingsStore } from "store";
import {
  BulkApproveListingsFunction,
  BulkApproveListingsFunctionParams,
} from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseBulkApproveListings extends BaseAsyncHook {
  bulkApproveListings: BulkApproveListingsFunction;
}

export const useBulkApproveListings = (): UseBulkApproveListings => {
  const _storeFunc = useListingsStore((store) => store.bulkApproveListings);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const bulkApproveListings = async (
    params: BulkApproveListingsFunctionParams
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);
      return await _storeFunc(params);
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    bulkApproveListings,
  };
};
