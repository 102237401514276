import dayjs from "dayjs";
import _ from "lodash";

import { HttpService } from "services";
import { DateString } from "types/common";
import { GetVendorAvailableDatesInMonthFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Get dates that listing can be scheduled for an event.
 * 
 * @NOTE: Currently, calling side can't specify event type
 *
 * @throws {UserServiceException}
 */
export const getVendorAvailableDatesInMonth: GetVendorAvailableDatesInMonthFunction =
  async (...args: Parameters<GetVendorAvailableDatesInMonthFunction>) => {
    try {
      const offset = new Date().getTimezoneOffset();

      const response = await HttpService.fetch<Record<DateString, boolean>>(
        `/user/availability/month?${new URLSearchParams({
          month: (args[0].month ?? dayjs().month()).toString(),
          year: (args[0].year ?? dayjs().year()).toString(),
          clientOffset: offset.toString(),
          vendorEventType: "photo-shoot",
        })}`,
        {
          requestConfig: {
            method: "GET",
          },
        },
        AuthorizationLevel.AUTHORIZED
      );

      return response;
    } catch (error) {
      throw error;
    }
  };
