import { Grid, GridProps, TextField, IconButton, Button } from "@mui/material";
import { Text, ListTable, Table } from "components/common";
import {
  useGetListings,
  useGetListing,
  useGetListingSeller,
  useGetEvents,
  useGetOffers,
  useRemoveListing,
} from "hooks";
import React, { useEffect, useState } from "react";
import { useListingsStore } from "store";
import { TableData, TableDataItem, TableDataItemValue } from "types/components";
import {
  DetailedListing,
  Listing,
  ListingMoreProperties,
  Event,
  Offer,
  ListingSeller,
} from "types/entities";
import {
  INITIAL_LISTINGS_TABLE_DATA,
  INITIAL_LISTING_GENERIC_TABLE_DATA,
  INITIAL_LISTING_DETAILS_TABLE_DATA,
  INITIAL_LISTING_EVENTS_TABLE_DATA,
  INITIAL_LISTING_OFFERS_TABLE_DATA,
} from "./";
import { useEventsStore, useOffersStore } from "store";
import { getFormattedDate } from "utils";
import {
  ManageListingsUpdateForm,
  ManageListingsPhotoSelector,
  ManageListingsInspectionDocs,
} from "components/listing";
import {} from "components/listing";
import { DIMENSIONS } from "consts";
import { ViewWrapper } from "components/wrappers";
import { notification } from "antd";
import { RemoveListingFunctionParams } from "types/functions";
import { SearchOutlined } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SplitscreenIcon from "../../assets/images/Frame.svg";

export const ManageListings: React.FC = () => {
  const { getListings } = useGetListings();
  const { getListing } = useGetListing();
  const { getListingSeller } = useGetListingSeller();
  const {
    loading: loadingRemoveListing,
    error: errorRemoveListing,
    removeListing,
  } = useRemoveListing();
  const { getEvents } = useGetEvents({
    configuration: { preventNetworkRequest: true },
  });
  const { getOffers } = useGetOffers();
  const { listings, listing, listingSeller } = useListingsStore();
  const { events } = useEventsStore();
  const { offers } = useOffersStore();
  const [listingsTableData, setListingsTableData] = useState<TableData>({
    ...INITIAL_LISTINGS_TABLE_DATA,
  });
  const [listingDetailsTableData, setListingDetailsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_DETAILS_TABLE_DATA });
  const [listingFeaturesTableData, setListingFeaturesTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingConditionsTableData, setListingConditionsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingFloodInfoTableData, setListingFloodInfoTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingMoreInfoTableData, setListingMoreInfoTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingDefectsTableData, setListingDefectsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingExclusionsTableData, setListingExclusionsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingSellerTableData, setListingSellerTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingEventsTableData, setListingEventsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_EVENTS_TABLE_DATA });
  const [listingOffersTableData, setListingOffersTableData] =
    useState<TableData>({ ...INITIAL_LISTING_OFFERS_TABLE_DATA });
  const [api, contextHolder] = notification.useNotification();

  const [selectedListing, setSelectedListing] = useState<string>("");

  const handleRowClick = (
    item: TableDataItem,
    setStateFunc: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setStateFunc(item.id as string);
  };

  /**
   * Maps data while adhering their key->title mapping set in their INITIAL_*_TABLE_DATA const var
   */
  const mapTranslatedDataToTableData = (
    data: Listing[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapEventsToTableData = (
    data: Event[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };
    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Event;

        // Handle cases requiring data translation
        if (key === "inviter" || key === "invitee") {
          if (Object.keys(item[key]).length === 0) {
            tableItem[key] = "-";
          } else {
            tableItem[key] = `${item[key].given_name} ${item[key].family_name}`;
          }
          return;
        }
        if (key === "minutes_start" || key === "minutes_end") {
          tableItem[key] =
            Math.floor(item[key] / 60)
              .toString()
              .padStart(2, "0") +
            ":" +
            Math.floor(item[key] % 60)
              .toString()
              .padStart(2, "0") +
            " UTC";
          return;
        }
        if (key === "date_specific") {
          const date = new Date(item[key]);
          tableItem[key] = getFormattedDate(date);
          return;
        }
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapOffersToTableData = (
    data: Offer[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };
    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Offer;

        // Handle cases requiring data translation
        if (key === "user") {
          tableItem[key] = `${item[key].given_name} ${item[key].family_name}`;
          return;
        }
        if (key === "closing_date" || key === "last_update_date") {
          const date = new Date(item[key]);
          tableItem[key] = getFormattedDate(date);
          return;
        }

        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapListingDetailsToTableData = (listing: DetailedListing) => {
    const newTableData: TableData = {
      ...INITIAL_LISTING_DETAILS_TABLE_DATA,
      data: [],
    };
    const data: TableDataItem = {};

    newTableData.headers.forEach((header) => {
      const key = header.key as keyof DetailedListing;
      data[key] = listing[key] as TableDataItemValue;
    });
    newTableData.data = [data];

    setListingDetailsTableData({ ...newTableData });
  };

  /**
   * Maps data while disregarding their key->title mapping set in their INITIAL_*_TABLE_DATA const var
   */
  const mapNonTranslatedDetailsToListTableData = (
    data: ListingMoreProperties[keyof ListingMoreProperties] | ListingSeller,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const keys = Object.keys(data);
    const newTableData: TableData = {
      headers: keys.map((key) => ({ key, title: key })),
      data: [],
    };
    newTableData.data = [data as TableDataItem];

    setStateFunc({ ...newTableData });
  };

  const fetchListingDetails = (selectedListing: string) => {
    const listing = listings.find((listing) => listing.id === selectedListing);
    if (listing) {
      getListing({ listingId: selectedListing });
      getOffers({ listingId: selectedListing });
    } else alert(`Can't find a listing with id: ${selectedListing}`);
  };

  useEffect(() => {
    mapTranslatedDataToTableData(
      listings,
      INITIAL_LISTINGS_TABLE_DATA,
      setListingsTableData
    );
  }, [listings]);

  useEffect(() => {
    if (!selectedListing) return;
    fetchListingDetails(selectedListing);
  }, [selectedListing]);

  useEffect(() => {
    // Check if listing is empty
    if (!listing || JSON.stringify(listing) === JSON.stringify({})) return;

    // Typescript doesn't seem to care that we've just checked whether listing object
    // is empty. Unfortunately we need to spam type assertion.
    mapListingDetailsToTableData(listing as DetailedListing);
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.features,
      setListingFeaturesTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.conditions,
      setListingConditionsTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.defects,
      setListingDefectsTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.flood_info,
      setListingFloodInfoTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.more_info,
      setListingMoreInfoTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.exclusions,
      setListingExclusionsTableData
    );
    getListingSeller({ listing: listing as DetailedListing });
    getEvents({ listingId: (listing as DetailedListing).id });
  }, [listing]);

  useEffect(() => {
    // Check if listingSeller is empty
    if (!listingSeller || JSON.stringify(listingSeller) === JSON.stringify({}))
      return;

    mapNonTranslatedDetailsToListTableData(
      listingSeller as ListingSeller,
      setListingSellerTableData
    );
  }, [listingSeller]);

  useEffect(() => {
    mapEventsToTableData(
      events,
      INITIAL_LISTING_EVENTS_TABLE_DATA,
      setListingEventsTableData
    );
  }, [events]);

  useEffect(() => {
    mapOffersToTableData(
      offers,
      INITIAL_LISTING_OFFERS_TABLE_DATA,
      setListingOffersTableData
    );
  }, [offers]);

  useEffect(() => {
    getListings();
  }, []);

  useEffect(() => {
    if (!loadingRemoveListing && !errorRemoveListing) {
      setSelectedListing("");
    }
  }, [loadingRemoveListing, errorRemoveListing]);

  interface GridItemProps extends GridProps {
    children: React.ReactNode;
  }
  const GridItem: React.FC<GridItemProps> = ({ children, ...props }) => (
    <Grid
      item
      xs={3}
      maxHeight={DIMENSIONS.MAX_GRID_ITEM_HEIGHT}
      overflow="auto"
      {...props}
    >
      {children}
    </Grid>
  );

  return (
    <ViewWrapper title="Manage Listings">
      {contextHolder}
      <GridItem>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <TextField
            id="standard-bare"
            // style={{borderRadius:"30px"}}
            defaultValue="Search"
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
              style: {
                borderRadius: "30px",
                height: "40px",
                width: "400px",
              },
            }}
          />
          <Button
            variant="outlined"
            style={{
              backgroundColor: "black",
              textTransform: "none",
              borderRadius: "30px",
              color: "white",
              border: "1px solid #DDDDDD",
            }}
            startIcon={<FilterListIcon />}
          >
            Filter
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text marginBottom="20px" marginTop="20px">
            253 Results
          </Text>
          <Button
            style={{
              backgroundColor: "white",
              borderRadius: "30px",
              textTransform: "none",
              color: "black",
              border: "1px solid #DDDDDD",
              width: "180px",
            }}
            startIcon={<img src={SplitscreenIcon} />}
          >
            Choose Columns
          </Button>
        </div>
        <Table
          tableData={listingsTableData}
          onRowClick={(item) => handleRowClick(item, setSelectedListing)}
          selectedRowId={selectedListing}
        />
      </GridItem>
      <>
        {selectedListing && (
          <>
            <div style={{ marginBottom: "40px" }}>
              <ManageListingsUpdateForm
                listing={listing as DetailedListing}
                removeListing={async (params: RemoveListingFunctionParams) => {
                  const res = await removeListing(params);
                  api.success({
                    message: `Listing removed`,
                    placement: "bottomRight",
                  });
                  return res;
                }}
                loadingRemoveListing={loadingRemoveListing}
                errorRemoveListing={!!errorRemoveListing}
              />
            </div>
            <Grid container spacing={2} alignItems="flex-start">
              <GridItem>
                <Text variant="h5" fontWeight={600} marginBottom="20px">
                  Details
                </Text>
                <ListTable tableData={listingDetailsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h5" fontWeight={600} marginBottom="20px">
                  Features
                </Text>
                <ListTable tableData={listingFeaturesTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h5" fontWeight={600} marginBottom="20px">
                  Conditions
                </Text>
                <ListTable tableData={listingConditionsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h5" fontWeight={600} marginBottom="20px">
                  Flood Info
                </Text>
                <ListTable tableData={listingFloodInfoTableData} />
              </GridItem>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              marginTop="20px"
            >
              <GridItem>
                <Text variant="h5" fontWeight={600} marginBottom="20px">
                  More Info
                </Text>
                <ListTable tableData={listingMoreInfoTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h5" fontWeight={600} marginBottom="20px">
                  Defects
                </Text>
                <ListTable tableData={listingDefectsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h5" fontWeight={600} marginBottom="20px">
                  Exclusions
                </Text>
                <ListTable tableData={listingExclusionsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h5" fontWeight={600} marginBottom="20px">
                  Seller Info
                </Text>
                <ListTable tableData={listingSellerTableData} />
              </GridItem>
            </Grid>

            <GridItem marginTop="20px">
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Events
              </Text>
              <Table tableData={listingEventsTableData} />
            </GridItem>

            <GridItem marginTop="20px">
              <Text variant="h5" fontWeight={600} marginBottom="20px">
                Offers
              </Text>
              <Table tableData={listingOffersTableData} />
            </GridItem>

            <div style={{ marginTop: "36px" }}>
              <ManageListingsInspectionDocs
                selectedListing={selectedListing}
                reloadListing={() => fetchListingDetails(selectedListing)}
              />
            </div>

            <div style={{ marginTop: "36px" }}>
              <ManageListingsPhotoSelector
                listing={listing}
                reloadListing={() => fetchListingDetails(selectedListing)}
              />
            </div>
          </>
        )}
      </>
    </ViewWrapper>
  );
};
