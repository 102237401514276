import { useState } from "react";

import { useUserStore } from "store";
import { GetVendorAvailableDatesInMonthFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetVendorAvailableDatesInMonth extends BaseAsyncHook {
  getVendorAvailableDatesInMonth: GetVendorAvailableDatesInMonthFunction;
  clearError: Function
}

export const useGetVendorAvailableDatesInMonth =
  (): UseGetVendorAvailableDatesInMonth => {
    const _storeFunc = useUserStore(
      (state) => state.getVendorAvailableDatesInMonth
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const getVendorAvailableDatesInMonth = async (
      ...args: Parameters<GetVendorAvailableDatesInMonthFunction>
    ) => {
      try {
        setIsLoading(true);
        setIsError(false);
        const response = await _storeFunc(args[0]);
        return response;
      } catch (error) {
        setIsError(true);
        return {};
      } finally {
        setIsLoading(false);
      }
    };

    return {
      error: isError,
      loading: isLoading,
      getVendorAvailableDatesInMonth,
      clearError: () => setIsError(false)
    };
  };
