import create from "zustand";

import { WalkthroughUrlService } from "services";
import { SetWalkthroughUrlFunction } from "types/functions/setWalkthroughUrl";

interface WalkthroughUrlStore {
  setWalkthroughUrl: SetWalkthroughUrlFunction;
}

export const useWalkthroughUrlStore = create<WalkthroughUrlStore>((set) => ({
  setWalkthroughUrl: async (...args: Parameters<SetWalkthroughUrlFunction>) => {
    try {
      return WalkthroughUrlService.setWalkthroughUrl(...args)
    } catch (error) {
      throw error;
    }
  },
}));
