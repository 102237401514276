import { CircularProgress, CircularProgressProps } from "@mui/material";
import { COLORS } from "consts";

interface OwnProps extends CircularProgressProps {
  isVisible?: boolean;
  indicatorColor?: string;
}

export const LoadingIndicator: React.FC<OwnProps> = (props) => {
  const { isVisible = true, indicatorColor = COLORS.PRIMARY, size = 24, ...circularProgressProps } = props;
  if (isVisible) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={size} {...circularProgressProps} style={{ ...props?.style, color: indicatorColor }} />
      </div>
    );
  } else return null;
};