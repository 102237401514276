import dayjs from "dayjs";

/**
 * Function that translates a date to a given date format.
 */
export const format = (date: Date | string, format?: string): string => {
  let _format = "MM-DD-YYYY";
  if (format) _format = format;

  return dayjs(date).format(_format);
};
