import { useState } from "react";

import { useListingsStore } from "store";
import { UploadBulkListingDocFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseUploadBulkListingDoc extends BaseAsyncHook {
  uploadBulkListingDoc: UploadBulkListingDocFunction;
}

export const useUploadBulkListingDoc = (): UseUploadBulkListingDoc => {
  const _stateFunc = useListingsStore((s) => s.uploadBulkListingDoc);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean | string>(false);

  const uploadBulkListingDoc = async (
    ...args: Parameters<UploadBulkListingDocFunction>
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await _stateFunc(args[0]);
      return response;
    } catch (error: any) {
      setIsError(error.response.data.payload.message ?? true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    uploadBulkListingDoc,
  };
};
