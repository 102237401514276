import { useState } from "react";

import { useListingsStore } from "store";
import { UploadBulkListingFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseUploadBulkListing extends BaseAsyncHook {
  uploadBulkListing: (
    ...args: Parameters<UploadBulkListingFunction>
  ) => Promise<boolean | undefined>;
  clearError: Function
}

export const useUploadBulkListing = (): UseUploadBulkListing => {
  const _stateFunc = useListingsStore((s) => s.uploadBulkListing);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean | string>(false);

  const uploadBulkListing = async (
    ...args: Parameters<UploadBulkListingFunction>
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);
      await _stateFunc(args[0]);
    } catch (error: any) {
      console.log({ error });
      const errData = error.response.data.payload.data;
      setIsError(
        errData !== null && typeof errData === "object"
          ? Object.entries(errData).reduce(
              (acc, x) => `${acc}\n${x.join(": ")}`,
              ""
            )
          : true
      );
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    uploadBulkListing,
    clearError: () => setIsError(false)
  };
};
