import { useState } from "react";

import { useUserStore } from "store";
import { SetVendorZipcodesFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";
import { ChangeFunctionReturnType } from "types/utils";

interface UseSetVendorZipcodes extends BaseAsyncHook {
  setVendorZipcodes: ChangeFunctionReturnType<
    SetVendorZipcodesFunction,
    Promise<void>
  >;
}

export const useSetVendorZipcodes = (): UseSetVendorZipcodes => {
  const _setVendorZipcodes = useUserStore((state) => state.setVendorZipcodes);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const setVendorZipcodes = async (
    ...args: Parameters<SetVendorZipcodesFunction>
  ) => {
    try {
      setIsLoading(true);
      setIsError(false);
      await _setVendorZipcodes(...args);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    setVendorZipcodes,
  };
};
