import { HttpService } from "services";
import { SignOutFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Log out.
 *
 * @throws {UserServiceException}
 */
export const signOut: SignOutFunction = async (): Promise<void> => {
  try {
    await HttpService.fetch(
      "/auth/logout",
      {
        requestConfig: {
          method: "POST",
        },
      },
      AuthorizationLevel.UNAUTHORIZED
    );

    HttpService.TokensManager.setAccessToken("");
    HttpService.TokensManager.setRefreshToken("");
  } catch (error) {
    throw error;
  }
};
