import { useState } from "react";

import { useDocumentStore } from "store";
import { RemoveDocsFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseRemoveDocs extends BaseAsyncHook {
  removeDocs: RemoveDocsFunction;
}

export const useRemoveDocs = (): UseRemoveDocs => {
  const _storeFunc = useDocumentStore((state) => state.removeDocs);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const removeDocs = async (...args: Parameters<RemoveDocsFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      await _storeFunc(args[0]);
      return true;
    } catch (error) {
      setIsError(true);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    removeDocs,
  };
};
