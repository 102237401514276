import { HttpService } from "services";
import { ApiUser } from "types/api";
import { User } from "types/entities";
import { SignInFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * Authenticate.
 *
 * @throws {UserServiceException}
 */
export const signIn: SignInFunction = async (
  ...args: Parameters<SignInFunction>
): Promise<User> => {
  try {
    const [email, password] = args;
    const response = await HttpService.fetch<{
      access_token: string;
      refresh_token: string;
      user: ApiUser;
    }>(
      "/auth/login-local",
      {
        requestConfig: {
          method: "POST",
          data: {
            email,
            password,
            return_refresh_token: true,
          },
        },
      },
      AuthorizationLevel.UNAUTHORIZED
    );
    HttpService.TokensManager.setAccessToken(response.access_token);
    HttpService.TokensManager.setRefreshToken(response.refresh_token);

    return HttpService.Serializer.serializeUser(response.user);
  } catch (error) {
    throw error;
  }
};
