import { Box } from "@mui/material";
import { PaperSurface, Text } from "components/common";
import { useListingsStore, useUserStore } from "store";
import "../../assets/styles/components/messages/styles.css";
import { ReactComponent as UnreadIcon } from "../../assets/images/UnreadIcon.svg";
import { generateListingPhotoUrls, shortenText } from "utils";
import { GroupChannel } from "sendbird";
import { generateLastMessage, generateLastMessageDate } from "utils/messages";

const BlankProfileImage = require("../../assets/images/BlankProfileImage.png");
const DefaultListingImage = require("../../assets/images/DefaultListingImage.png");

type OwnProps = {
  // conversation: GroupChannel
  channel: GroupChannel;
  isSelected?: boolean;
  onClick: () => void;
};

export const ChatRow: React.FC<OwnProps> = ({
  isSelected,
  channel,
  onClick,
}) => {
  const { authorizedUser } = useUserStore();
  const { listings } = useListingsStore();
  const userId = authorizedUser?.id;
  const data = JSON.parse(channel.data || "");
  const isUnread = !!channel?.unreadMessageCount;

  const lastMessage = generateLastMessage(channel);
  const lastMessageDate = generateLastMessageDate(channel);
  const channelName = channel.name || "Invalid Channel Name";
  const listingId: string | undefined = data?.listingId;

  const getChannelImage = () => {
    if (listingId) {
      const listing = listings.find((listing) => listing.id === listingId);

      if (!listing) return DefaultListingImage;
      const { photo_urls } = listing;
      if (photo_urls.length === 0) return DefaultListingImage;

      const firstListingPhoto = generateListingPhotoUrls(photo_urls, "sm")[0];
      return firstListingPhoto;
    }

    const sendbirdProfilePictureUrl = channel.members.find(
      (member) =>
        member.userId !== userId &&
        member.plainProfileUrl &&
        member.plainProfileUrl.length > 0
    )?.plainProfileUrl;
    if (!sendbirdProfilePictureUrl) return BlankProfileImage;
    return sendbirdProfilePictureUrl;
  };

  return (
    <PaperSurface
      className={`chatrow-container ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <UnreadIcon
        className="unread-icon"
        style={{ visibility: isUnread ? "visible" : "hidden" }}
      />
      <img className="profile-img" src={getChannelImage()} alt="Profile pic" />
      <Box className="chat-info">
        <Box className="name-line">
          <Text className="name">{channelName}</Text>
          <Text className="time">{lastMessageDate}</Text>
        </Box>
        <Text className="message">
          {/* @ts-ignore */}
          {shortenText(lastMessage, 55)}
        </Text>
      </Box>
    </PaperSurface>
  );
};
