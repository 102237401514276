import { useEffect, useState } from "react";

import { useUserStore } from "store";
import { GetVendorsWithZipcodesFunction } from "types/functions";
import { BaseAsyncHook, BaseAsyncHookConfiguration } from "types/hooks";
import { UsersInStore } from "types/store";
import { parseAsyncHookConfiguration } from "utils";
import { useGetVendors } from "hooks";

interface UseGetVendors extends BaseAsyncHook {
  loading: boolean;
  error: boolean;
  vendors: UsersInStore;
}

/**
 * Retrieve Vendors along with their zip codes assignments.
 */
export const useGetVendorsWithZipcodes = (
  props?: BaseAsyncHookConfiguration
): UseGetVendors => {
  const config = parseAsyncHookConfiguration(props);

  const _getVendorsWithZipcodes = useUserStore(
    (state) => state.getVendorsWithZipcodes
  );

  const _vendors = useGetVendors({
    configuration: {
      preventNetworkRequest: true,
    },
  }).vendors;

  const [isLoading, setIsLoading] = useState<boolean>(
    config.preventNetworkRequest ? false : true
  );
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const onLoad = async (
      ...args: Parameters<GetVendorsWithZipcodesFunction>
    ) => {
      try {
        setIsError(false);
        await _getVendorsWithZipcodes(...args);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (config.preventNetworkRequest === false) onLoad();
  }, [_getVendorsWithZipcodes, config.preventNetworkRequest]);

  return {
    error: isError,
    loading: isLoading,
    vendors: _vendors,
  };
};
