import { HttpService } from "services";
import { DetailedListing } from "types/entities";
import { UploadBulkListingDocFunction } from "types/functions";
import { AuthorizationLevel } from "types/http";

/**
 * ...
 *
 * @throws {HttpServiceException}
 */
export const uploadBulkListingDoc: UploadBulkListingDocFunction = async (
  ...args: Parameters<UploadBulkListingDocFunction>
) => {
  try {
    const { document } = args[0];

    const formData = new FormData();
    formData.append("document", document);

    const res = await HttpService.fetch<{ listings: DetailedListing[] }>(
      "/listing/parse-bulk-document",
      {
        requestConfig: {
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res.listings;
  } catch (error) {
    throw error;
  }
};
