import { BaseAsyncHookConfiguration } from "types/hooks";

/**
 * Build and resolve async hook options/configuration object.
 *
 * This object is usually not required (in hooks) just like parameters inside it.
 * This function checks which parameters are present and resolves them to a
 * definitive values.
 *
 */
export const parseAsyncHookConfiguration = (
  input?: BaseAsyncHookConfiguration
): Required<BaseAsyncHookConfiguration> => {
  // Configuration with default values. Later to be changed.
  const configuration: Required<BaseAsyncHookConfiguration> = {
    preventNetworkRequest: false,
  };

  // Fallback to default configuration.
  if (!input) return configuration;

  // Get available options.
  const { preventNetworkRequest: preventNetworkRequestOnMount } = input;

  // Resolve options.
  if (preventNetworkRequestOnMount === true) {
    configuration.preventNetworkRequest = true;
  }

  return configuration;
};
