import {
  Alert,
  Backdrop,
  Chip,
  CircularProgress,
  Grid,
  Snackbar,
  Stack,
} from "@mui/material";
import "react-calendar/dist/Calendar.css";
import { InputField, Select, Table, Text } from "components/common";
import { ViewWrapper } from "components/wrappers";
import {
  useGetUsers,
  useGetVendorAvailableDatesInMonth,
  useGetVendorAvailableTimeslots,
  useUploadBulkListing,
  useUploadBulkListingDoc,
} from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { Button, UploadFile, notification } from "antd";
import { InboxOutlined, UploadOutlined } from "@mui/icons-material";
import Dragger from "antd/es/upload/Dragger";
import { DetailedListing, User } from "types/entities";
import { RcFile } from "antd/es/upload";
import { INITIAL_BULK_LISTINGS } from "views/ManageListings";
import { FormListing, ListingSchedule } from "types/components";
import { appendToTableData } from "utils/components";
import { ListingEditForm } from "components/listing";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import { DateString } from "types/common";
import { ApiTimeslot } from "types/api";
import { DateFormatter } from "utils";
import { apiDateString } from "consts";
import { numberToTimeString } from "utils/DateFormatter";

const Context = React.createContext({ name: "BulkAddListings" });

/**
 * In this view, an Admin can parse many listing at once from
 * `.CSV` file and edit them before upload.
 */
export const BulkAddListings: React.FC = () => {
  const { uploadBulkListingDoc, ...bulkUploadState } =
    useUploadBulkListingDoc();
  const { getUsers, USER_TYPES, ...getUsersState } = useGetUsers();
  const { uploadBulkListing, ...uploadBulkListingState } =
    useUploadBulkListing();
  const [api, contextHolder] = notification.useNotification();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [groupName, setGroupName] = useState<string>("");
  // Holds a list of listings and Photography Event dates
  // This list will be sent to a backend service to create listings
  const [bulkUploadData, setBulkUploadData] = useState<
    FormListing[] | undefined
  >();
  const [sellers, setSellers] = useState<User[] | undefined>();
  const [selectedSellerId, setSelectedSellerId] = useState<
    string | undefined
  >();
  const [selectedListing, setSelectedListing] = useState<string | undefined>();

  const { getVendorAvailableDatesInMonth, ...vendorDatesFetchingState } =
    useGetVendorAvailableDatesInMonth();
  const { getVendorAvailableTimeslots, ...vendorTimeslotsFetchingState } =
    useGetVendorAvailableTimeslots();
  const [datesAvailable, setDatesAvailable] = useState<
    Record<DateString, boolean> | undefined
  >();
  const [selectedScheduleDate, setSelectedScheduleDate] =
    useState<DateString | null>(null);
  const [selectedScheduleTimeslots, setSelectedScheduleTimeslots] =
    useState<ApiTimeslot | null>(null);
  // Serves as a cache to limit number of requests made to the server
  const [dateTimeslotsHashMap, setDateTimeslotsHashMap] = useState<
    Record<DateString, ApiTimeslot[]>
  >({});

  const fetchSellers = async (): Promise<void> => {
    if (bulkUploadData && bulkUploadData.length > 0 && !sellers) {
      const sellersResult = await getUsers(USER_TYPES["PLAIN_USER"]);
      setSellers(sellersResult);
    }
  };

  // Scheduling related procedures to run on component mount
  useEffect(() => {
    (async () => {
      // Set schedule date to today
      const today = dayjs().format(apiDateString) as DateString;
      setSelectedScheduleDate(today);
      // Fetch available days for this month and year
      setDatesAvailable(await getVendorAvailableDatesInMonth({}));
    })();
  }, []);

  // Fetch timeslots on new selected schedule date
  useEffect(() => {
    (async () => {
      if (selectedScheduleDate === null) return;

      setSelectedScheduleTimeslots(null);
      setBulkUploadData((current) => {
        if (!current) return undefined;
        return current.map((listing) => ({ ...listing, schedule: undefined }));
      });

      const timeslotsInCache = dateTimeslotsHashMap[selectedScheduleDate];
      // Stop if timeslots exists
      if (timeslotsInCache?.length > 0) return;

      const timeslots = await getVendorAvailableTimeslots({
        dateSpecific: selectedScheduleDate,
      });
      setDateTimeslotsHashMap((curr) => ({
        ...curr,
        [selectedScheduleDate]: timeslots,
      }));
    })();
  }, [selectedScheduleDate]);

  // Fetch Sellers
  // It fetches Sellers only once - when first non-empty document was parsed
  useEffect(() => {
    fetchSellers();
  }, [bulkUploadData, sellers]);

  const handleUploadTemplateFile = async () => {
    const antdFile = fileList[0];
    const listings = await uploadBulkListingDoc({
      document: antdFile as RcFile,
    });
    setBulkUploadData(
      listings.map((l) => ({
        listing: l,
        schedule: undefined,
      }))
    );
    setFileList([]);
    uploadBulkListingState.clearError();
    setSelectedSellerId(undefined);
    setSelectedListing(undefined);
    setGroupName("");
    setSelectedScheduleTimeslots(null);
    setSelectedScheduleTimeslots(null);
    if (listings.length > 0) {
      api.success({
        message: `Successfully read ${listings.length} listing(s)`,
        placement: "bottomRight",
      });
    }
  };

  const handleFormSubmit = async () => {
    if (bulkUploadData === undefined) return;

    const res = await uploadBulkListing({
      data: {
        bulk: (bulkUploadData.find(
          (formListing) => formListing.schedule === undefined
        ) !== undefined
          ? []
          : bulkUploadData) as Required<FormListing>[],
        seller_id: selectedSellerId!,
        group_name: groupName,
      },
    });
    if (res === false) return;
    setFileList([]);
    setSellers(undefined);
    setSelectedSellerId(undefined);
    api.success({
      message: `Successfully submited ${bulkUploadData.length} listing(s)`,
      placement: "bottomRight",
    });
    setBulkUploadData(undefined);
  };

  const renderErrorAlert = (
    error: string | boolean | undefined
  ): JSX.Element => {
    const isBool = typeof error === "boolean";
    if (error === undefined || (isBool && error === false)) return <></>;

    const errorMessage =
      isBool && error === true
        ? "An unknown error occured. Please try again"
        : error;

    return <Alert severity="error">{errorMessage}</Alert>;
  };

  const pickedListing: undefined | FormListing =
    selectedListing && bulkUploadData
      ? bulkUploadData[parseInt(selectedListing)]
      : undefined;

  const contextValue = useMemo(() => ({ name: "" }), []);

  const handleFileRemove = (file: UploadFile<any>): void => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const preFileUpload = async (file: RcFile): Promise<boolean> => {
    setFileList([file]);
    return false;
  };

  const generateTimeslotsPicker = (
    availableTimeslots: ApiTimeslot[],
    selectedScheduleDate: DateString
  ): JSX.Element => {
    return (
      <Grid container direction={"column"} spacing={2} paddingX={5}>
        <Grid container spacing={2}>
          {availableTimeslots.map((timeslot, i) => {
            const offset = new Date().getTimezoneOffset() * -1;
            const isPicked =
              selectedScheduleTimeslots !== null &&
              timeslot.minutes_end === selectedScheduleTimeslots.minutes_end &&
              timeslot.minutes_start ===
                selectedScheduleTimeslots.minutes_start;
            return (
              <Grid key={i} item xs={6}>
                <Chip
                  style={{ width: "100%" }}
                  variant={isPicked ? "filled" : "outlined"}
                  size="medium"
                  color="info"
                  label={
                    <Text
                      fontSize={17}
                      fontWeight={400}
                      color={isPicked ? "white" : "black"}
                    >{`${numberToTimeString(
                      timeslot.minutes_start + offset
                    )} - ${numberToTimeString(
                      timeslot.minutes_end + offset
                    )}`}</Text>
                  }
                  onClick={(_) => {
                    setSelectedScheduleTimeslots(timeslot);
                    setBulkUploadData((curr) =>
                      curr
                        ? curr.map((listing) => ({
                            ...listing,
                            schedule: {
                              ...timeslot,
                              date_specific: selectedScheduleDate,
                            },
                          }))
                        : undefined
                    );
                  }}
                />
              </Grid>
              //   )
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <ViewWrapper title="Bulk add listings">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={bulkUploadState.loading || uploadBulkListingState.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Stack direction={"column"} spacing={3}>
          <Stack spacing={3}>
            <Dragger
              disabled={bulkUploadState.loading}
              fileList={fileList}
              accept=".csv"
              maxCount={1}
              multiple={false}
              onRemove={handleFileRemove}
              beforeUpload={preFileUpload}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Currentl only .CSV format is supported
              </p>
            </Dragger>
            <Button
              disabled={fileList.length === 0 || bulkUploadState.loading}
              onClick={handleUploadTemplateFile}
              icon={<UploadOutlined />}
              className="bulk-upload-button"
            >
              Upload
            </Button>
          </Stack>

          {bulkUploadState.error !== false &&
            renderErrorAlert(bulkUploadState.error)}

          {sellers && getUsersState.loading !== true && (
            <Select
              options={sellers.map((seller) => ({
                label: `${seller.givenName} ${seller.familyName}`,
                value: seller.id,
              }))}
              value={selectedSellerId || ""}
              label="Pick a Seller"
              onChange={setSelectedSellerId}
            />
          )}

          {getUsersState.error !== false &&
            renderErrorAlert(getUsersState.error)}

          {bulkUploadData &&
            bulkUploadData.length > 0 &&
            bulkUploadState.loading === false && (
              <InputField
                label="Group name"
                value={groupName}
                onChange={setGroupName}
                required
                fullWidth
              />
            )}
          <Snackbar
            open={
              vendorTimeslotsFetchingState.error !== false ||
              vendorDatesFetchingState.error !== false
            }
            autoHideDuration={6000}
            message="Scheduling events is unavailable. You won't be able to save your listings. PLease, try again later"
          />
          {bulkUploadData &&
            bulkUploadData.length > 0 &&
            bulkUploadState.loading === false && (
              <>
                {vendorTimeslotsFetchingState.error ||
                vendorDatesFetchingState.error ? (
                  <Grid key="calendar_error" item xs={12}>
                    <Alert severity="error">
                      Scheduling events is unavailable.
                    </Alert>
                  </Grid>
                ) : (
                  <Grid
                    key="calendar"
                    item
                    flexDirection={"column"}
                    marginTop={4}
                    xs={12}
                  >
                    <Grid container direction={"column"} marginBottom={2}>
                      <Text variant="h5">Schedule a Photo Shoot</Text>
                      <Text variant="subtitle1">
                        Pick time and date for a photo shoot event
                      </Text>
                    </Grid>
                    <Grid item container direction={"row"}>
                      <Grid item xs={6}>
                        <Calendar
                          key={selectedScheduleDate}
                          showNeighboringMonth={false}
                          locale="en"
                          minDetail="year"
                          maxDetail="month"
                          className="schedule-react-calendar"
                          minDate={dayjs().toDate()}
                          tileClassName={({ date, view }) => {
                            if (view === "month") {
                              const now = dayjs();
                              const parsedDate = DateFormatter.format(
                                date,
                                apiDateString
                              ) as DateString;

                              const isSelectedDate =
                                parsedDate === selectedScheduleDate;
                              // const isSelectedForSchedule =
                              //   parsedDate === selectedScheduleDate;
                              const isAvailable =
                                datesAvailable !== undefined &&
                                datesAvailable[parsedDate] &&
                                dayjs(date).isAfter(now.subtract(1, "day"));

                              if (isSelectedDate) {
                                return "calendar-selected-date";
                              }
                              // if (isSelectedForSchedule) {
                              //   return "calendar-focused-date";
                              // }
                              if (isAvailable) {
                                return "calendar-available";
                              }
                            }
                          }}
                          value={
                            selectedScheduleDate
                              ? dayjs(
                                  selectedScheduleDate,
                                  apiDateString
                                ).toDate()
                              : undefined
                          }
                          onChange={(date) =>
                            date && !Array.isArray(date)
                              ? setSelectedScheduleDate(
                                  DateFormatter.format(
                                    date,
                                    apiDateString
                                  ) as DateString
                                )
                              : undefined
                          }
                          onActiveStartDateChange={async ({
                            activeStartDate,
                          }) => {
                            if (activeStartDate) {
                              const startDate = dayjs(activeStartDate);
                              const dates =
                                await getVendorAvailableDatesInMonth({
                                  month: startDate.get("month"),
                                  year: startDate.get("year"),
                                });
                              setDatesAvailable(dates);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {selectedScheduleDate !== null &&
                          dateTimeslotsHashMap[selectedScheduleDate] !==
                            undefined &&
                          generateTimeslotsPicker(
                            dateTimeslotsHashMap[selectedScheduleDate],
                            selectedScheduleDate
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

          {bulkUploadData &&
            bulkUploadData.length > 0 &&
            bulkUploadState.loading === false && (
              <>
                <div>
                  <Text variant="h5">Review listings</Text>
                  <Text variant="subtitle1">
                    Select listings and edit them below
                  </Text>
                </div>
                <Table
                  tableData={appendToTableData<
                    Record<keyof DetailedListing, unknown>
                  >(
                    INITIAL_BULK_LISTINGS,
                    bulkUploadData.map((d, i) => ({
                      ...d.listing,
                      displayId: i + 1,
                    }))
                  )}
                  onRowClick={(item) =>
                    setSelectedListing(item.id ? item.id.toString() : undefined)
                  }
                  selectedRowId={selectedListing}
                />
              </>
            )}

          {bulkUploadData && pickedListing && (
            <ListingEditForm
              formData={pickedListing}
              onChange={(formListing) => {
                if (!selectedListing) return;
                const newState = [...bulkUploadData];
                newState[parseInt(selectedListing)] = formListing;
                setBulkUploadData(newState);
                uploadBulkListingState.clearError();
              }}
            />
          )}

          {uploadBulkListingState.error !== false &&
            renderErrorAlert(uploadBulkListingState.error)}

          {bulkUploadData !== undefined && (
            <Button
              size="large"
              type="primary"
              onClick={handleFormSubmit}
              loading={uploadBulkListingState.loading}
              disabled={
                selectedSellerId === undefined ||
                bulkUploadData === undefined ||
                groupName.length === 0 ||
                bulkUploadData.find(
                  (formListing) => formListing.schedule === undefined
                ) !== undefined
              }
            >
              Bulk upload Listings
            </Button>
          )}
        </Stack>
      </ViewWrapper>
    </Context.Provider>
  );
};
