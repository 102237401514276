import { LocalStorageKeys } from "types/localStorage";

export const getAccessToken = (): string | null => {
  return localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token);
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);
};

export const setRefreshToken = (token: string): void => {
  localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, token);
};
