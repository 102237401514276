import create from "zustand";

import {
  GetEventsFunction,
} from "types/functions";
import { EventService } from "services";
import { Event } from "types/entities";

interface EventsStore {
  events: Event[],
  getEvents: GetEventsFunction,
}

export const useEventsStore = create<EventsStore>((set) => ({
  events: [],
  getEvents: async (...args: Parameters<GetEventsFunction>) => {
    try {
      const events = await EventService.getEvents(...args);
      set({ events });
      
      return events;
    } catch (error) {
      throw error;
    }
  },
}));
