import { useState } from "react";

import { useMessagesStore } from "store";
import { GetChannelsFunction } from "types/functions";
import { BaseAsyncHook } from "types/hooks";

interface UseGetChannels extends BaseAsyncHook {
  getChannels: GetChannelsFunction;
}

export const useGetChannels = (): UseGetChannels => {
  const _storeFunc = useMessagesStore((state) => state.getChannels);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getChannels = async (...args: Parameters<GetChannelsFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const channels = await _storeFunc(args[0]);
      return channels;
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getChannels,
  };
};
