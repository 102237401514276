import { Paper, PaperProps } from "@mui/material";
import React from "react";

interface OwnProps extends PaperProps {}

type TPaperSurface = OwnProps;

export const PaperSurface: React.FC<TPaperSurface> = ({
  children,
  ...props
}) => {
  return <Paper {...props}>{children}</Paper>;
};
