import { TableData } from "types/components";

export const INITIAL_LISTINGS_TABLE_DATA: TableData = {
  headers: [
    {
      title: "Approved",
      key: "approved",
    },
    {
      title: "Address Line 1",
      key: "address_line_1",
    },
    {
      title: "Address Line 2",
      key: "address_line_2",
    },
    {
      title: "City",
      key: "city",
    },
    {
      title: "State",
      key: "state",
    },
    {
      title: "Zip",
      key: "zip",
    },
    {
      title: "List Price",
      key: "list_price",
    },
    {
      title: "Beds",
      key: "beds",
    },
    {
      title: "Baths",
      key: "baths",
    },
    {
      title: "Square Ft.",
      key: "sqft",
    },
    {
      title: "Lot Size(Square Ft.)",
      key: "lot_size_sqft",
    },
    {
      title: "Closing Date",
      key: "closing_date",
    },
    {
      title: "Longitude",
      key: "longitude",
    },
    {
      title: "Latitude",
      key: "latitude",
    },
  ],
  data: [],
};
