import { Box, Stack } from "@mui/material";
import { ErrorMessage, LoadingIndicator, Table, Text } from "components/common";
import { TitleDocumentsTable, TitlesSelectionTable, TitleStatusTable } from "components/title";
import { ViewWrapper } from "components/wrappers";
import { MAX_GRID_ITEM_HEIGHT } from "consts/dimensions";
import { useGenerateTableData, useGetListings } from "hooks";
import React, { useEffect, useState } from "react";
import { useListingsStore, useTitleStore } from "store";
import { TableDataItemValue } from "types/components";
import { EndpointTitle, Listing } from "types/entities";

interface OwnProps {}

export const ManageTitles: React.FC<OwnProps> = () => {
  const { getListings, loading, error } = useGetListings();
  const { listings } = useListingsStore();
  const { titles } = useTitleStore();
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [selectedTitle, setSelectedTitle] = useState<EndpointTitle | null>(
    null
  );
  const listingsTableData = useGenerateTableData({
    columnNames: ["Address Line 1", "Address Line 2", "City", "State", "Zip"],
    data: listings
      ? listings.map((listing) => ({
          hidden: [
            {
              name: "_id",
              value: listing.id,
            },
          ],
          visible: [
            listing.address_line_1,
            listing.address_line_2,
            listing.city,
            listing.state,
            listing.zip,
          ],
        }))
      : [],
    onRowClick: ({ _id }) => {
      if (_id && typeof _id === "string") {
        const newSelectedListing = listings.find(
          (listing) => listing.id === _id
        );
        setSelectedListing(
          newSelectedListing ? { ...newSelectedListing } : null
        );
      } else {
        console.warn(
          "Unable to handle row click in table, _id of Listing is not present."
        );
      }
    },
  });

  useEffect(() => {
    getListings();
  }, []);

  const onTitleSelectionRowClick: (
    item: Record<string, TableDataItemValue>
  ) => void = ({ _id }) => {
    const newSelectedTitle = titles.find(
      (title) => title.transactionId === _id
    );
    setSelectedTitle(newSelectedTitle ? { ...newSelectedTitle } : null);
  };

  return (
    <ViewWrapper title="Manage titles">
      <Stack spacing={2}>
        <Box>
          {listings.length > 0 && (
            <Text variant="h5" fontWeight={600}>
              Select a listing
            </Text>
          )}

          {loading ? (
            <LoadingIndicator />
          ) : (
            <div style={{ maxHeight: MAX_GRID_ITEM_HEIGHT, overflow: "auto" }}>
              <Table
                dataIdKey="_id"
                selectedRowId={selectedListing?.id}
                tableData={listingsTableData}
                onRowClick={listingsTableData.onRowClick}
              />
            </div>
          )}
        </Box>
        {error && <ErrorMessage />}

        {selectedListing && (
          <Box>
            <TitlesSelectionTable
              listing={selectedListing}
              onRowClick={onTitleSelectionRowClick}
              selectedTitle={selectedTitle}
            />
          </Box>
        )}

        {selectedListing && selectedTitle && (
          <Box>
            <TitleStatusTable
              listing={selectedListing}
              selectedTitle={selectedTitle}
            />
          </Box>
        )}

        {selectedListing && selectedTitle && (
          <Box>
            <TitleDocumentsTable
              listing={selectedListing}
              selectedTitle={selectedTitle}
            />
          </Box>
        )}
      </Stack>
    </ViewWrapper>
  );
};
