import { HttpService } from "services";
import { ListingDocument } from "types/entities";

import {
  GetDocumentsFunction,
  GetDocumentsParams,
} from "types/functions/getDocuments";
import { AuthorizationLevel } from "types/http";
import { generateQueryString } from "utils";

/**
 * @TODO: add valid comment
 *
 * @throws {HttpServiceException}
 */
export const getDocuments: GetDocumentsFunction = async (
  params: GetDocumentsParams
) => {
  try {
    const { listingId } = params;
    let url = `/document/${generateQueryString({ listingId })}`;

    const res: ListingDocument[] = await HttpService.fetch(
      url,
      {
        requestConfig: {
          method: "GET",
        },
      },
      AuthorizationLevel.AUTHORIZED
    );

    return res;
  } catch (error) {
    throw error;
  }
};
