import {
  Paper,
  TableContainer,
  TableRow,
  TableBody,
  Table as TableBase,
  TableCell,
} from "@mui/material";
import { TableData } from "types/components/table";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Text } from "../Text";
import { TableWrapper } from "components/common";

type OwnProps = {
  tableData: TableData;
};

export const ListTable: React.FC<OwnProps> = (props) => {
  const { tableData } = props;

  const generateTableBody = (tableData: TableData) => {
    const tableRows: JSX.Element[] = [];

    const cellStyle = {
      width: "50%",
    };

    tableData.headers.forEach((item) => {
      const key = item.key as string;
      const value = tableData.data[0][key];

      const labelCell = (
        <TableCell key="0" style={cellStyle}>
          {item.title}
        </TableCell>
      );
      let valueCell;
      if (typeof value === "boolean") {
        valueCell = ( 
          <TableCell key={key} style={cellStyle}>
            {value ? <CheckIcon /> : <DoNotDisturbIcon />}
          </TableCell>
        );
      } else {
        valueCell = (
          <TableCell key={key} style={cellStyle}>
            {value || "-"}
          </TableCell>
        );
      }

      tableRows.push(
        <TableRow key={key}>
          {labelCell}
          {valueCell}
        </TableRow>
      );
    });

    return tableRows;
  };

  return (
    <>
      {!tableData?.data?.length ||
      JSON.stringify(tableData?.data[0]) === JSON.stringify({}) ? (
        <Text padding={1}>No data to be displayed</Text>
      ) : (
        <TableWrapper>
          <TableContainer component={Paper} className="margin-top-10">
            <TableBase size="small">
              <TableBody>{generateTableBody(tableData)}</TableBody>
            </TableBase>
          </TableContainer>
        </TableWrapper>
      )}
    </>
  );
};
