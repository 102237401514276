import create from "zustand";

import {
  GetTitleDocumentFunction,
  GetTitleDocumentsFunction,
  GetTitlesFunction,
  GetTitleStatusFunction,
  UploadTitleDocumentFunction,
} from "types/functions";
import { EndpointDownloadedTitleDocument, EndpointTitle, EndpointTitleDocument, EndpointTitleStatus } from "types/entities";
import { TitleService } from "services";
import {  } from "types/functions";

interface TitleStore {
  titles: EndpointTitle[];
  titleStatus: Partial<EndpointTitleStatus>;
  titleDocuments: EndpointTitleDocument[];
  titleDocument: Partial<EndpointDownloadedTitleDocument>;
  getTitles: GetTitlesFunction;
  getTitleStatus: GetTitleStatusFunction
  getTitleDocuments: GetTitleDocumentsFunction;
  getTitleDocument: GetTitleDocumentFunction;
  uploadTitleDocument: UploadTitleDocumentFunction;
}

export const useTitleStore = create<TitleStore>((set) => ({
  titles: [],
  titleStatus: {},
  titleDocuments: [],
  titleDocument: '',
  getTitles: async (...args: Parameters<GetTitlesFunction>) => {
    try {
      const titles = await TitleService.getTitles(...args);
      set({ titles });

      return titles;
    } catch (error) {
      throw error;
    }
  },
  getTitleStatus: async (...args: Parameters<GetTitleStatusFunction>) => {
    try {
      const titleStatus = await TitleService.getTitleStatus(...args);
      set({ titleStatus });

      return titleStatus;
    } catch (error) {
      throw error;
    }
  },
  getTitleDocuments: async (...args: Parameters<GetTitleDocumentsFunction>) => {
    try {
      const titleDocuments = await TitleService.getTitleDocuments(...args);
      set({ titleDocuments });

      return titleDocuments;
    } catch (error) {
      throw error;
    }
  },
  getTitleDocument: async (...args: Parameters<GetTitleDocumentFunction>) => {
    try {
      const titleDocument = await TitleService.getTitleDocument(...args);
      set({ titleDocument });

      return titleDocument;
    } catch (error) {
      throw error;
    }
  },
  uploadTitleDocument: async (...args: Parameters<UploadTitleDocumentFunction>) => {
    try {
      const response = await TitleService.uploadTitleDocument(...args);

      return response;
    } catch (error) {
      throw error;
    }
  },
}));
