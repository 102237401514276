import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table as TableBase,
  TableCell,
  TableSortLabel,
} from "@mui/material";
import {
  TableData,
  TableDataItem,
  TableHeaderItem,
} from "types/components/table";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Text } from "../Text";
import { TableWrapper } from "components/common/TableWrapper";

type OwnProps = {
  tableData: TableData;
  dataIdKey?: string;
  onRowClick?: (item: TableDataItem) => void;
  selectedRowId?: string;
  selectedRowIds?: string[];
};
export const Table: React.FC<OwnProps> = (props) => {
  const {
    tableData,
    onRowClick,
    selectedRowId,
    selectedRowIds = [],
    dataIdKey = "id",
  } = props;
  const generateTableHeaders = (tableData: TableData) =>
    tableData.headers.map((header) => (
      <TableCell
        key={header.key}
        style={{
          fontFamily: "PlusJakartaSans-Bold", // Set your font family for TableBody
        }}
      >
        {header.title}
      </TableCell>
    ));

  const generateTableBody = (tableData: TableData) => {
    const tableRows: JSX.Element[] = [];
    tableData.data.forEach((item, index) => {
      let rowKey = index.toString();
      const cells: JSX.Element[] = [];
      Object.keys(item).forEach((key) => {
        // Skip columns beginning with a underscore.
        if (key.match(/^_/g)) return;
        // Skip 'id' column
        if (key === dataIdKey) {
          rowKey = item[key] as string;
          return;
        }
        const value = item[key];
        let valueCell;

        if (typeof value === "boolean") {
          valueCell = (
            <TableCell key={key}>
              {value ? <CheckIcon /> : <DoNotDisturbIcon />}
            </TableCell>
          );
        } else {
          valueCell = (
            <TableCell
              key={key}
              style={{
                fontFamily: "PlusJakartaSans-Medium",

                color: "black",
              }}
            >
              {value || "-"}
            </TableCell>
          );
        }
        cells.push(valueCell);
      });
      tableRows.push(
        <TableRow
          hover={!!onRowClick}
          className={onRowClick ? "cursor-pointer" : ""}
          onClick={(e) => onRowClick && onRowClick(item)}
          key={rowKey}
          selected={
            selectedRowId === item[dataIdKey] ||
            selectedRowIds.includes(item[dataIdKey] as string)
          }
        >
          {cells}
        </TableRow>
      );
    });

    return <TableBody>{tableRows}</TableBody>;
  };

  return (
    <>
      {!tableData?.data?.length ? (
        <Text padding={1}>No data to be displayed</Text>
      ) : (
        <TableWrapper>
          <TableContainer component={Paper} className="margin-top-10">
            <TableBase size="small">
              <TableHead
                style={{
                  background: "#FFE500",
                  fontWeight: "700",
                }}
              >
                {tableData?.data?.length !== 0 && (
                  <TableRow>{generateTableHeaders(tableData)}</TableRow>
                )}
              </TableHead>
              {generateTableBody(tableData)}
            </TableBase>
          </TableContainer>
        </TableWrapper>
      )}
    </>
  );
};
