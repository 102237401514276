import { useState } from "react";

import { useOffersStore } from "store";
import { GetOffersFunction } from "types/functions/getOffers";
import { BaseAsyncHook } from "types/hooks";

interface UseGetOffers extends BaseAsyncHook {
  getOffers: GetOffersFunction;
}

export const useGetOffers = (): UseGetOffers => {
  const _getOffers = useOffersStore((state) => state.getOffers);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getOffers = async (...args: Parameters<GetOffersFunction>) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const offers = await _getOffers(args[0]);
      return offers;
    } catch (error) {
      setIsError(true);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error: isError,
    loading: isLoading,
    getOffers,
  };
};
